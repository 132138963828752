import React, {
  createContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import apiClient from "../api/apiClient";
import endpoints from "../api/endpoints";

export const NewsContext = createContext();

export const NewsProvider = ({ children }) => {
  const [newsList, setNewsList] = useState([]); 
  const [loading, setLoading] = useState(true);    // Trạng thái loading lần đầu
  const [loadingMore, setLoadingMore] = useState(false); // Trạng thái loading “trang sau”
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  // Cache ID để tránh thêm trùng lặp tin
  const cachedNewsIds = useRef(new Set());

  /**
   * Hàm tải tin tức (infinite scroll)
   * @param {number} currentPage – trang muốn tải
   */
  const fetchNews = useCallback(
    async (currentPage) => {
      if (loadingMore || !hasMore) return;  // Nếu đang load hoặc hết data => bỏ qua

      setLoadingMore(true);
      try {
        // Gọi API GET
        const response = await apiClient.get(
          `${endpoints.news.list}?page=${currentPage}&limit=10`
        );

        // Lấy mảng tin, tuỳ server trả về
        const rawData = Array.isArray(response.data)
          ? response.data
          : response.data.data;

        // Nếu không có dữ liệu => đánh dấu hết tin
        if (!rawData || rawData.length === 0) {
          setHasMore(false);
          return;
        }

        // Loại bỏ tin đã có ID trong cache (tránh trùng)
        const filteredData = rawData.filter(
          (newsItem) => !cachedNewsIds.current.has(newsItem.id)
        );

        // Thêm ID mới vào cache
        filteredData.forEach((newsItem) =>
          cachedNewsIds.current.add(newsItem.id)
        );

        // Nối dữ liệu vào state
        setNewsList((prev) => [...prev, ...filteredData]);
      } catch (error) {
        console.error("Error fetching news list:", error);
      } finally {
        setLoading(false);
        setLoadingMore(false);
      }
    },
    [hasMore, loadingMore]
  );

  /**
   * Gọi fetchNews mỗi khi `page` thay đổi
   */
  useEffect(() => {
    fetchNews(page);
    // eslint-disable-next-line
  }, [page]);

  /**
   * Hàm xoá bài viết
   * @param {string} newsId – ID bài cần xoá
   * - Gọi API xoá
   * - Nếu thành công, gỡ khỏi state & cache
   */
  const deleteNews = useCallback(
    async (newsId) => {
      try {
        // Gọi API xoá
        // Ở đây bạn cần token => tuỳ vào cách bạn lưu token 
        const token = localStorage.getItem("access_token");
        await apiClient.delete(endpoints.news.delete(newsId), {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Xoá khỏi state
        setNewsList((prev) => prev.filter((item) => item.id !== newsId));
        // Xoá khỏi cache
        cachedNewsIds.current.delete(newsId);
      } catch (error) {
        console.error("Error deleting news:", error);
      }
    },
    []
  );

  return (
    <NewsContext.Provider
      value={{
        newsList,
        loading,
        hasMore,
        page,
        setPage,
        deleteNews,      // <-- export hàm deleteNews
      }}
    >
      {children}
    </NewsContext.Provider>
  );
};
