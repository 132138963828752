import React, { useState, useEffect, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import Breadcrumb from "../components/Breadcrumb";
import apiClient from "../api/apiClient";
import endpoints from "../api/endpoints";
import {
  Button,
  TextField,
  IconButton,
  Typography,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
  Box,
  Pagination,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useDropzone } from "react-dropzone";
import "../styles/Threads.css";
import { formatDate, timeAgo } from "../utils/dateUtils";
import { UserContext } from "../context/UserContext";

const ThreadsPage = () => {
  const { user } = useContext(UserContext);
  const { categoryId } = useParams();
  const [topic, setTopic] = useState(null);
  const [threads, setThreads] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [newThread, setNewThread] = useState({ title: "", content: "" });
  const [showPopup, setShowPopup] = useState(false);
  const [showError, setShowError] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [threadToDelete, setThreadToDelete] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const fetchThreads = async (pageToFetch) => {
    try {
      setLoading(true);
      const response = await apiClient.get(
        endpoints.discussionCategory.topicIdThread(categoryId, pageToFetch, 10)
      );

      const { data: fetchedThreads = [], total = 0 } = response.data;
      setThreads(fetchedThreads);
      setTotalPages(Math.ceil(total / 10));
    } catch (error) {
      setError("Không thể tải dữ liệu. Vui lòng thử lại!");
    } finally {
      setLoading(false);
    }
  };

  const fetchInitialData = async () => {
    try {
      setLoading(true);
      const topicResponse = await apiClient.get(
        endpoints.discussionCategory.topicId(categoryId)
      );
      setTopic(topicResponse.data);

      const token = localStorage.getItem("access_token");
      if (token) {
        const decodedToken = JSON.parse(atob(token.split(".")[1]));
        setIsAdmin(decodedToken.roles.includes("admin"));
      }

      await fetchThreads(1);
    } catch (error) {
      setError("Không thể tải dữ liệu. Vui lòng thử lại!");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (categoryId) {
      fetchInitialData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId]);

  const handlePageChange = (event, value) => {
    setPage(value);
    fetchThreads(value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewThread((prev) => ({ ...prev, [name]: value }));
  };

  const handleContentChange = (value) => {
    setNewThread((prev) => ({ ...prev, content: value }));
  };

  const handleImageUpload = async (file) => {
    const formData = new FormData();
    formData.append("image", file);

    try {
      const response = await apiClient.post(
        "https://upload.genyz.vn/upload",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      const imageUrl = response.data.public_image;
      const content = `${newThread.content}<img src="${imageUrl}" alt="Uploaded Image" />`;
      setNewThread((prev) => ({ ...prev, content }));
    } catch (error) {
      setError("Không thể tải lên hình ảnh. Vui lòng thử lại!");
    }
  };

  const handleDrop = (acceptedFiles) => {
    acceptedFiles.forEach((file) => handleImageUpload(file));
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    const accessToken = localStorage.getItem("access_token");
    if (!accessToken) {
      setShowError(true);
      return;
    }

    try {
      await apiClient.post(endpoints.discussionCategory.createThread, {
        ...newThread,
        topicId: categoryId,
      });
      fetchThreads(page); // Refresh current page
      setNewThread({ title: "", content: "" });
      setShowPopup(false);
      setShowError(false);
    } catch (error) {
      setError("Không thể tạo thread. Vui lòng thử lại!");
    }
  };

  const handleDeleteOpen = (threadId) => {
    setThreadToDelete(threadId);
    setOpenDeleteDialog(true);
  };

  const handleDeleteClose = () => {
    setOpenDeleteDialog(false);
    setThreadToDelete(null);
  };

  const handleDelete = async () => {
    const accessToken = localStorage.getItem("access_token");
    try {
      await apiClient.delete(
        endpoints.discussionCategory.deleteThread(threadToDelete),
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      fetchThreads(page); // Refresh current page
      handleDeleteClose();
    } catch (error) {
      setError("Không thể xóa thread. Vui lòng thử lại!");
      handleDeleteClose();
    }
  };

  const closePopup = () => {
    setShowPopup(false);
    setShowError(false);
  };

  if (loading) {
    return <div className="threads-loading">Đang tải...</div>;
  }

  return (
    <div className="threads-container">
      <Breadcrumb subName={topic?.title} />
      <div style={{ margin: "10px 0", textAlign: "right" }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => setShowPopup(true)}
        >
          Thêm mới
        </Button>
      </div>

      {threads.length > 0 && (
        <>
          <Typography
            style={{
              background: `linear-gradient(to right, #2937ff, #29f4ff)`,
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
              textTransform: "uppercase",
              fontWeight: 600,
            }}
          >
            {topic?.title}
          </Typography>
          <Box sx={{ textAlign: "center", padding: "20px 0" }}>
            <Pagination
              count={totalPages}
              page={page}
              onChange={handlePageChange}
              color="primary"
              variant="outlined"
              shape="rounded"
            />
          </Box>
        </>
      )}
      {showPopup && (
        <div
          className="popup"
          onClick={closePopup}
          style={{ height: "100%", overflow: "auto" }}
        >
          <div
            className="popup-content"
            onClick={(e) => e.stopPropagation()}
            style={{ width: "100%", height: "100%", padding: "20px" }}
          >
            <Typography variant="h6">BÀI VIẾT MỚI</Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={closePopup}
              aria-label="close"
              style={{ position: "absolute", right: 20, top: 20 }}
            >
              <CloseIcon />
            </IconButton>
            {showError && (
              <Alert severity="error">
                Bạn cần đăng nhập để sử dụng tính năng này.
              </Alert>
            )}
            <form onSubmit={handleSubmit}>
              <TextField
                variant="outlined"
                name="title"
                placeholder="Tiêu đề"
                value={newThread.title}
                onChange={handleChange}
                required
                fullWidth
                margin="normal"
              />
              <div
                {...getRootProps()}
                style={{
                  border: "2px dashed #ccc",
                  padding: "10px",
                  marginBottom: "15px",
                  textAlign: "center",
                }}
              >
                <input {...getInputProps()} />
                {isDragActive ? (
                  <p>Kéo thả hình ảnh vào đây ...</p>
                ) : (
                  <p>Kéo thả hình ảnh hoặc nhấp để chọn hình ảnh</p>
                )}
              </div>
              <ReactQuill
                value={newThread.content}
                onChange={handleContentChange}
                placeholder="Nội dung"
                style={{ height: "280px", marginBottom: "115px" }}
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                style={{ width: "100%", padding: "15px" }}
              >
                CẬP NHẬT
              </Button>
            </form>
          </div>
        </div>
      )}
      <div className="threads">
        {threads.length === 0 ? (
          <div className="no-threads-message">
            Chưa có bài viết nào trong chuyên mục này.
          </div>
        ) : (
          threads.map((thread, index) => (
            <div
              key={`${thread._id} - ${index}`}
              className="thread-item"
              style={{ position: "relative" }}
            >
              <div className="thread-avatar">
                <img
                  src={thread.createdBy?.avatar || user.avatar}
                  alt="Thread Avatar"
                />
              </div>
              <div className="thread-content">
                <h3>
                  <Link
                    to={`/categories/${categoryId}/threads/${thread._id}`}
                    state={{ data: topic.title }}
                  >
                    {thread.title}
                  </Link>
                </h3>
                <div className="thread-meta">
                  Bởi {thread.createdBy?.name} • {formatDate(thread.createdAt)}{" "}
                  • {timeAgo(thread.createdAt)}
                  {isAdmin && (
                    <IconButton
                      color="secondary"
                      onClick={() => handleDeleteOpen(thread._id)}
                      aria-label="delete"
                      style={{ float: "right", color: "red" }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </div>
              </div>
            </div>
          ))
        )}
      </div>
      {/* <Box sx={{ display: "flex", justifyContent: "center", margin: "20px 0" }}></Box> */}
      {threads.length > 0 && (
        <>
          <Box sx={{ textAlign: "center", padding: "20px 0" }}>
            <Pagination
              count={totalPages}
              page={page}
              onChange={handlePageChange}
              color="primary"
              variant="outlined"
              shape="rounded"
            />
          </Box>
          <Breadcrumb subName={topic?.title} />
        </>
      )}

      <Dialog open={openDeleteDialog} onClose={handleDeleteClose}>
        <DialogTitle>Xác Nhận Xóa</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Bạn có chắc chắn muốn xóa thread này? Hành động này không thể hoàn
            tác.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteClose} color="primary">
            Hủy
          </Button>
          <Button onClick={handleDelete} color="secondary">
            Xóa
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ThreadsPage;
