import React, { useContext } from "react";
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  Avatar,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AccountCircle } from "@mui/icons-material";
import "../styles/Menu.css";

import HeaderLogo from "../components/HeaderLogo";
import { UserContext } from "../context/UserContext";

const DesktopMenu = () => {
  const { user, setUser, loading } = useContext(UserContext); // Lấy thông tin từ UserContext
  const [anchorEl, setAnchorEl] = React.useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  const menuItems = [
    { id: 1, label: "Trang chủ", link: "/home" },
    { id: 2, label: "Tin nhanh", link: "/news" },
    { id: 3, label: "Mẹo vặt", link: "/tips-and-tricks" },
    // { id: 4, label: "Kết nối", link: "/products" },
    { id: 4, label: "Chuyên Mục", link: "/categories" },
  ];

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem("access_token");
    setUser(null); // Cập nhật trạng thái user trong context
    setAnchorEl(null); // Đóng menu
    navigate("/home"); // Chuyển hướng về trang chủ
  };

  if (loading) {
    return <div>Đang tải...</div>; // Hiển thị trạng thái loading nếu cần
  }

  return (
    <Box className="menu-container">
      {/* Logo */}
      <Box className="menu-logo-container">
        <Link to="/home" className="menu-logo-link">
          <HeaderLogo />
        </Link>
      </Box>

      {/* Menu Items */}
      <Box className="menu-items-container">
        {menuItems.map((item) => (
          <Link
            key={item.id}
            to={item.link}
            className={`menu-item ${(location.pathname === item.link ||
                location.pathname.startsWith(item.link) ||
                (["/posts", "/user-wall"].some(path => location.pathname.startsWith(path)) && item.id === 1)
              )
                ? "menu-item-active"
                : ""
              }`}
          >
            {item.label}
          </Link>
        ))}
      </Box>

      {/* User Account Section */}
      <Box
        sx={{
          marginLeft: "auto",
          display: "flex",
          alignItems: "center",
        }}
      >
        <IconButton
          onClick={handleMenuClick}
          sx={{
            color: "#15293f",
            padding: 1,
            "&:hover": {
              backgroundColor: "#e7e7e3",
            },
          }}
        >
          {user ? (
            <Avatar alt={user.name} src={user.avatar} />
          ) : (
            <AccountCircle sx={{ fontSize: 32 }} />
          )}
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          sx={{ mt: 1 }}
        >
          {user
            ? [
              <MenuItem key="profile" onClick={handleMenuClose}>
                <Link to={`/profile`} className="dropdown-link">
                  <Typography sx={{ fontSize: "14px" }}>Trang cá nhân</Typography>
                </Link>
              </MenuItem>,

              <MenuItem key="info-profile" onClick={handleMenuClose}>
                <Link
                  to="/account/personal-details"
                  className="dropdown-link"
                >
                  <Typography sx={{ fontSize: "14px" }}>Thông tin cá nhân</Typography>
                </Link>
              </MenuItem>,

              <MenuItem key="info-profile-change-password" onClick={handleMenuClose}>
                <Link
                  to="/account/change-password"
                  className="dropdown-link"
                >
                  <Typography sx={{ fontSize: "14px" }}>Đổi mật khẩu</Typography>
                </Link>
              </MenuItem>,
              <MenuItem key="logout" onClick={handleLogout}>
                <Typography sx={{ fontSize: "14px" }}>Đăng xuất</Typography>
              </MenuItem>,
            ]
            : [
              <MenuItem key="login" onClick={handleMenuClose}>
                <Link to="/login" className="dropdown-link">
                  Đăng nhập tài khoản
                </Link>
              </MenuItem>,
              <MenuItem key="register" onClick={handleMenuClose}>
                <Link to="/register" className="dropdown-link">
                  Đăng ký tài khoản mới
                </Link>
              </MenuItem>,
            ]}
        </Menu>
      </Box>
    </Box>
  );
};

export default DesktopMenu;
