import React, { useState } from "react";
import { Box, Button, TextField, Typography, Snackbar, Alert } from "@mui/material";
import apiClient from "../api/apiClient";
import endpoints from "../api/endpoints";
import { useNavigate } from "react-router-dom";

const ChangePasswordPage = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(null); // Error state
  const [success, setSuccess] = useState(false); // Success state
  const navigate = useNavigate();

  const handleChangePassword = async () => {
    if (newPassword !== confirmPassword) {
      setError("Mật khẩu mới không khớp với xác nhận mật khẩu.");
      return;
    }

    try {
      const accessToken = localStorage.getItem("access_token");

      if (!accessToken) {
        setError("Không tìm thấy token xác thực.");
        return;
      }

      const response = await apiClient.put(
        endpoints.users.changePassword,
        {
          oldPassword,
          newPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.status === 200) {
        setSuccess(true);
        setTimeout(() => {
          navigate("/profile"); // Chuyển hướng đến trang hồ sơ hoặc trang khác sau khi đổi mật khẩu thành công
        }, 2000);
      }
    } catch (err) {
      setError("Đổi mật khẩu thất bại. Vui lòng thử lại.");
    }
  };

  return (
    <Box
      sx={{
        maxWidth: "400px",
        margin: "50px auto",
        padding: "20px",
        backgroundColor: "#fff",
        borderRadius: "8px",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        textAlign: "center",
      }}
    >
      <Typography sx={{ marginBottom: "20px", textTransform: "uppercase", fontWeight: "bold" }}>
        Đổi mật khẩu
      </Typography>
      <TextField
        fullWidth
        label="Mật khẩu cũ"
        type="password"
        margin="normal"
        value={oldPassword}
        onChange={(e) => setOldPassword(e.target.value)}
      />
      <TextField
        fullWidth
        label="Mật khẩu mới"
        type="password"
        margin="normal"
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
      />
      <TextField
        fullWidth
        label="Xác nhận mật khẩu"
        type="password"
        margin="normal"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
      />
      <Button
        fullWidth
        variant="contained"
        color="primary"
        sx={{ marginTop: "20px" }}
        onClick={handleChangePassword}
      >
        Đổi mật khẩu
      </Button>

      {/* Snackbar thông báo lỗi */}
      {error && (
        <Snackbar
          open={!!error}
          autoHideDuration={4000}
          onClose={() => setError(null)}
        >
          <Alert severity="error" onClose={() => setError(null)}>
            {error}
          </Alert>
        </Snackbar>
      )}

      {/* Snackbar thông báo thành công */}
      {success && (
        <Snackbar
          open={success}
          autoHideDuration={2000}
          onClose={() => setSuccess(false)}
        >
          <Alert severity="success" onClose={() => setSuccess(false)}>
            Đổi mật khẩu thành công!
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};

export default ChangePasswordPage;
