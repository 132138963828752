import React, { useState } from "react";
import { Box, Button, TextField, Typography, Snackbar, Alert } from "@mui/material";
import { useNavigate } from "react-router-dom";
import apiClient from "../api/apiClient";
import endpoints from "../api/endpoints";

const ResetPasswordPage = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null); // Error state
  const [success, setSuccess] = useState(false); // Success state
  const navigate = useNavigate();

  const handleResetPassword = async () => {
    try {
      const response = await apiClient.post(endpoints.users.resetPassword, {
        email,
      });

      if (response.status === 200) {
        setSuccess(true);
        setTimeout(() => {
          navigate("/login"); // Redirect to login after success
        }, 2000);
      }
    } catch (err) {
      setError("Đặt lại mật khẩu thất bại. Vui lòng kiểm tra lại email.");
    }
  };

  return (
    <Box
      sx={{
        maxWidth: "400px",
        margin: "50px auto",
        padding: "20px",
        backgroundColor: "#fff",
        borderRadius: "8px",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        textAlign: "center",
      }}
    >
      <Typography sx={{ marginBottom: "20px", textTransform: "uppercase", fontWeight: "bold" }}>
        Đặt lại mật khẩu
      </Typography>
      <TextField
        fullWidth
        label="Tên tài khoản hoặc Email"
        margin="normal"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <Button
        fullWidth
        variant="contained"
        color="primary"
        sx={{ marginTop: "20px" }}
        onClick={handleResetPassword}
      >
        Gửi yêu cầu
      </Button>

      {/* Snackbar thông báo lỗi */}
      {error && (
        <Snackbar
          open={!!error}
          autoHideDuration={4000}
          onClose={() => setError(null)}
        >
          <Alert severity="error" onClose={() => setError(null)}>
            {error}
          </Alert>
        </Snackbar>
      )}

      {/* Snackbar thông báo thành công */}
      {success && (
        <Snackbar
          open={success}
          autoHideDuration={2000}
          onClose={() => setSuccess(false)}
        >
          <Alert severity="success" onClose={() => setSuccess(false)}>
            Yêu cầu đặt lại mật khẩu đã được gửi! Kiểm tra email của bạn.
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};

export default ResetPasswordPage;
