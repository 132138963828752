import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Typography, Avatar, CircularProgress } from "@mui/material";
import apiClient from "../api/apiClient";
import endpoints from "../api/endpoints";
import "../styles/ProductDetails.css";

const ProductDetails = () => {
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProductDetails = async () => {
      try {
        const response = await apiClient.get(endpoints.products.details(id));
        setProduct(response.data);
      } catch (err) {
        setError("Không thể tải thông tin sản phẩm.");
      } finally {
        setLoading(false);
      }
    };

    fetchProductDetails();
  }, [id]);

  if (loading) {
    return (
      <Box sx={{ textAlign: "center", padding: "20px" }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ textAlign: "center", padding: "20px", color: "#ff0000" }}>
        <Typography>{error}</Typography>
      </Box>
    );
  }

  return (
    <Box className="product-details-container">
      <Typography variant="h4" className="product-title">
        {product.name}
      </Typography>
      <Avatar
        src={product.image || "https://via.placeholder.com/150"}
        alt={product.name}
        sx={{ width: 150, height: 150, margin: "20px auto" }}
      />
      <Typography variant="body1" className="product-description">
        {product.description}
      </Typography>
      <Typography variant="h6" className="product-price">
        {product.price}
      </Typography>
    </Box>
  );
};

export default ProductDetails;