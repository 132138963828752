// src/utils/dateUtils.js

// Hàm định dạng ngày tháng theo dd/MM/yyyy hh:mm
export const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date)) return "Không rõ ngày";
  
    return date.toLocaleString("vi-VN", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };
  
  // Hàm tính khoảng thời gian cách đây bao lâu
  export const timeAgo = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date)) return "";
  
    const now = new Date();
    const seconds = Math.floor((now - date) / 1000);
  
    if (seconds < 60) return `${seconds} giây trước`;
    const minutes = Math.floor(seconds / 60);
    if (minutes < 60) return `${minutes} phút trước`;
    const hours = Math.floor(minutes / 60);
    if (hours < 24) return `${hours} tiếng trước`;
    const days = Math.floor(hours / 24);
    if (days < 30) return `${days} ngày trước`;
    const months = Math.floor(days / 30);
    if (months < 12) return `${months} tháng trước`;
    const years = Math.floor(months / 12);
    return `${years} năm trước`;
  };
  