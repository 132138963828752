import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { UserProvider } from "./context/UserContext";
import { NewsProvider } from "./context/NewsContext";
import { ShortNewsProvider } from "./context/ShortNewsContext"; // Sử dụng ShortNewsProvider
import { HelmetProvider } from "react-helmet-async";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <UserProvider>
        <NewsProvider>
          <ShortNewsProvider>
            {" "}
            {/* Sử dụng ShortNewsProvider */}
            <App />
          </ShortNewsProvider>
        </NewsProvider>
      </UserProvider>
    </HelmetProvider>
  </React.StrictMode>
);

reportWebVitals();
