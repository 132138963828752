import React, { useContext, useState } from "react";
import { Box, Typography, Button, Divider, Grid } from "@mui/material";
import { AddCircleOutline, CheckCircle, HourglassEmpty } from "@mui/icons-material";
import { UserContext } from "../context/UserContext";
import apiClient from "../api/apiClient"; // Ensure this API client is imported
import endpoints from "../api/endpoints";
import "../styles/IdentifyVerification.css";

const IdentityVerification = ({ kycImages, setKycImages }) => {
    const { user, setUser, loading, error } = useContext(UserContext);
    // const [selectedDocumentType, setSelectedDocumentType] = useState('cccd');
    const [frontImage, setFrontImage] = useState(null);
    const [backImage, setBackImage] = useState(null);
    const [previewFrontImage, setPreviewFrontImage] = useState(kycImages[0] || null);
    const [previewBackImage, setPreviewBackImage] = useState(kycImages[1] || null);
    const [isLoading, setIsLoading] = useState(false);

    // Hàm upload chung cho cả front và back image
    const uploadImage = async (file) => {
        const formData = new FormData();
        formData.append("image", file);

        try {
            const response = await apiClient.post('https://upload.genyz.vn/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            return response.data.public_image;
        } catch (error) {
            console.error("Lỗi tải lên hình ảnh:", error);
            alert("Không thể tải lên hình ảnh.");
            throw new Error("Upload failed");
        }
    };

    // Handle Front Image Change (Avatar style)
    const handleFrontImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFrontImage(file);
            const objectUrl = URL.createObjectURL(file);
            setPreviewFrontImage(objectUrl);
        }
    };

    // Handle Back Image Change (Avatar style)
    const handleBackImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setBackImage(file);
            const objectUrl = URL.createObjectURL(file);
            setPreviewBackImage(objectUrl);
        }
    };

    // Handle Submit (Confirm)
    const handleSubmit = async () => {
        if (!frontImage || !backImage) {
            alert("Please upload both front and back images.");
            return;
        }

        setIsLoading(true);

        try {
            // Upload Front Image
            const frontImageUrl = await uploadImage(frontImage);

            // Upload Back Image
            const backImageUrl = await uploadImage(backImage);

            // Set kycImages
            setKycImages([frontImageUrl, backImageUrl]);

            // Update user's profile
            const updatedProfile = {
                ...user,
                kycStatus: "pending", // Change status to pending
                kycImages: [frontImageUrl, backImageUrl], // Set kycImages
            };

            // Update profile with new KYC images and status
            await apiClient.put(endpoints.users.profile, updatedProfile);
            setUser(updatedProfile);

            alert("User profile updated successfully!");
        } catch (error) {
            console.error("Error updating profile:", error);
            alert("Error updating profile, please try again.");
        } finally {
            setIsLoading(false);
        }
    };

    // Render KYC status and determine if the upload form should be visible
    const renderKycStatus = () => {
        if (user.kycStatus === "verified") {
            return (
                <Box sx={{ textAlign: "center", color: "green", padding: "20px" }}>
                    <CheckCircle sx={{ fontSize: 50, marginBottom: 2, color: "green" }} />
                    <Typography >Tài khoản đã xác minh</Typography>
                </Box>
            );
        }

        if (user.kycStatus === "pending") {
            return (
                <Box sx={{ textAlign: "center", color: "#ff9800" }}>
                    <HourglassEmpty sx={{ fontSize: 50, marginBottom: 2 }} />
                    <Typography>Tài khoản đang chờ xác minh</Typography>
                </Box>
            );
        }

        // If kycStatus is 'none' or undefined, show the upload form
        return (
            <Box sx={{ textAlign: "center", border: "1px solid rgb(59, 59, 59)", color: "gray", padding: "20px" }}>
                <Typography>Tài khoản chưa xác minh</Typography>
            </Box>
        );
    };

    // Check if kycStatus is 'none' to display the upload form
    const renderUploadForm = () => {
        if (user.kycStatus === "none") {
            return (
                <>
                    <br />
                    <Divider sx={{ margin: "20px 0", border: '1px solid rgb(59, 59, 59)' }} />
                    <br />
                    <Typography sx={{ marginBottom: "30px", color: "#fff", fontWeight: "bold" }}>Xác Thực Tài Khoả (CCCD)</Typography>
                    {/* Front and Back Image Section */}
                    <Grid container spacing={2} sx={{ marginBottom: "20px" }}>
                        {/* Front Image */}
                        <Grid item xs={12} sm={12}>
                            <Typography variant="body1" sx={{ marginBottom: "10px", color: "#fff" }}>Ảnh Mặt Trước</Typography>
                            {previewFrontImage ? (
                                <Box
                                    sx={{
                                        width: "100%",
                                        height: "150px",
                                        backgroundColor: "#ddd",
                                        border: "2px dashed #003366",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        overflow: "hidden",
                                        marginBottom: "10px",
                                    }}
                                >
                                    <img
                                        src={previewFrontImage || user.kycImages[0]}
                                        alt="Front"
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            objectFit: "cover",
                                        }}
                                    />
                                </Box>
                            ) : (
                                <Box
                                    sx={{
                                        border: "2px dashed #003366",
                                        padding: "20px",
                                        textAlign: "center",
                                        cursor: "pointer",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        color: "#003366",
                                        fontSize: "16px",
                                        fontWeight: "bold",
                                        height: "150px",
                                        flexDirection: "column",
                                    }}
                                    onClick={() => document.getElementById("front-image-upload").click()}
                                >
                                    <AddCircleOutline sx={{ fontSize: 40, marginBottom: "10px" }} />
                                    <Typography>Ảnh chụp trực diện và rõ nét thông tin</Typography>
                                </Box>
                            )}
                            <input
                                type="file"
                                id="front-image-upload"
                                accept="image/*"
                                onChange={handleFrontImageChange}
                                style={{ display: "none" }}
                            />
                        </Grid>

                        {/* Back Image */}
                        <Grid item xs={12} sm={12}>
                            <Typography variant="body1" sx={{ marginBottom: "10px", color: "#fff" }}>Ảnh Mặt Sau</Typography>
                            {previewBackImage ? (
                                <Box
                                    sx={{
                                        width: "100%",
                                        height: "150px",
                                        backgroundColor: "#ddd",
                                        border: "2px dashed #003366",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        overflow: "hidden",
                                        marginBottom: "10px",
                                    }}
                                >
                                    <img
                                        src={previewBackImage || user.kycImages[1]}
                                        alt="Back"
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            objectFit: "cover",
                                        }}
                                    />
                                </Box>
                            ) : (
                                <Box
                                    sx={{
                                        border: "2px dashed #003366",
                                        padding: "20px",
                                        textAlign: "center",
                                        cursor: "pointer",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        color: "#003366",
                                        fontSize: "16px",
                                        fontWeight: "400",
                                        height: "150px",
                                        flexDirection: "column",
                                    }}
                                    onClick={() => document.getElementById("back-image-upload").click()}
                                >
                                    <AddCircleOutline sx={{ fontSize: 40, marginBottom: "10px" }} />
                                    <Typography>Ảnh chụp trực diện và rõ nét thông tin</Typography>
                                </Box>
                            )}
                            <input
                                type="file"
                                id="back-image-upload"
                                accept="image/*"
                                onChange={handleBackImageChange}
                                style={{ display: "none" }}
                            />
                        </Grid>
                    </Grid>

                    {/* Submit Button */}
                    <Button
                        variant="contained"
                        fullWidth
                        sx={{ marginBottom: "20px", marginTop: "30px", backgroundColor: "#003366", '&:hover': { backgroundColor: "#002244" } }}
                        onClick={handleSubmit}
                        disabled={isLoading}
                    >
                        {isLoading ? "Đang tải..." : "Xác Nhận"}
                    </Button>
                </>
            );
        }

        return null; // If KYC status is not "none", don't display the upload form.
    };

    if (loading) {
        return (
            <Box sx={{ textAlign: "center", padding: "20px", color: "#333" }}>
                <Typography>Đang tải...</Typography>
            </Box>
        );
    }

    if (error || !user) {
        return (
            <Box sx={{ textAlign: "center", padding: "20px", color: "#ff0000" }}>
                <Typography>{error || "Không thể tải thông tin cá nhân. Vui lòng đăng nhập."}</Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ padding: "20px", color: "#fff", textAlign: "left" }}>
            {/* KYC Status */}
            {renderKycStatus()}

            {/* Upload Form for KYC Images */}
            {renderUploadForm()}
        </Box>
    );
};

export default IdentityVerification;
