import React from "react";
import { formatDate, timeAgo } from "../utils/dateUtils";
import ReactMarkdown from "react-markdown";
import Button from "@mui/material/Button";

const isHTML = (content) => {
  const doc = new DOMParser().parseFromString(content, "text/html");
  return Array.from(doc.body.childNodes).some((node) => node.nodeType === 1);
};

const CommentItem = React.memo(({ comment, onHide, isAdmin }) => {
  return (
    <div className="comment-item">
      <div className="comment-content">
        <div className="comment-header">
          <span className="comment-author">{comment.createdBy.name}</span>
          <span className="comment-date">
            {formatDate(comment.createdAt)} {timeAgo(comment.createdAt)}
          </span>
        </div>
        <div className="comment-text">
          {isHTML(comment.content) ? (
            <div dangerouslySetInnerHTML={{ __html: comment.content }} />
          ) : (
            <ReactMarkdown>{comment.content}</ReactMarkdown>
          )}
        </div>
        {isAdmin && (
          <Button variant="outlined" color="secondary" onClick={() => onHide(comment._id)}>
            Ẩn
          </Button>
        )}
      </div>
    </div>
  );
});

export default CommentItem;