// src/pages/DetailsNews.jsx

import React, { useEffect, useState, useRef, useContext } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Typography,
  CircularProgress,
  Avatar,
  Slider,
  Tooltip,
  Button,
} from "@mui/material";
import { Close, PlayArrow, Pause } from "@mui/icons-material"; // Import PlayArrow và Pause
import { useLocation, useNavigate, Link, useParams } from "react-router-dom";
import { Helmet } from "react-helmet"; // Import Helmet
import ImageSlider from "../components/ImageSlider";
import ReactMarkdown from "react-markdown";
import HighlightHeaderArticle from "../components/HighlighHeaderArticle";
import apiClient from "../api/apiClient";
import endpoints from "../api/endpoints";
import { formatDate, timeAgo } from "../utils/dateUtils";
import { UserContext } from "../context/UserContext";
import ReactPlayer from "react-player";

import "../styles/DetailsNews.css";

const DetailsNews = () => {
  const { slug } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { id, scrollToComments } = location.state || {};
  const { user: currentUser } = useContext(UserContext);

  const [newsItem, setNewsItem] = useState(null);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState("");

  const commentsSectionRef = useRef(null);

  // States cho text-to-speech
  const [isSpeaking, setIsSpeaking] = useState(false);
  const [speechRate, setSpeechRate] = useState(1.25);
  const [speechPitch, setSpeechPitch] = useState(0.7);
  const [speechVolume, setSpeechVolume] = useState(0.45);
  const [showSlider, setShowSlider] = useState(false);
  const [availableVoices, setAvailableVoices] = useState([]);
  const [selectedVoice, setSelectedVoice] = useState(null);
  const utteranceRef = useRef(null);
  const sentencesRef = useRef([]); // Lưu trữ các câu để phát âm
  const currentSentenceIndexRef = useRef(0); // Lưu trữ chỉ số câu hiện tại

  useEffect(() => {
    const fetchNewsDetails = async () => {
      let fetchUrl = "";

      if (id) {
        fetchUrl = endpoints.news.details(id);
      } else if (slug) {
        fetchUrl = endpoints.news.detailsBySlug(slug);
      } else {
        navigate("/home");
        return;
      }

      try {
        const response = await apiClient.get(fetchUrl);
        const newsData = response.data;
        setNewsItem(newsData);
      } catch (error) {
        console.error("Lỗi khi lấy chi tiết tin tức:", error);
      } finally {
        setLoading(false);
      }
    };

    const scrollToCommentsSection = () => {
      if (scrollToComments && commentsSectionRef.current) {
        commentsSectionRef.current.scrollIntoView({ behavior: "smooth" });
      }
    };

    fetchNewsDetails();
    scrollToCommentsSection();
    window.scrollTo(0, 0);
  }, [id, navigate, scrollToComments, currentUser?.userId]);

  // Load available voices
  useEffect(() => {
    const loadVoices = () => {
      const voices = window.speechSynthesis.getVoices();
      setAvailableVoices(voices);

      // Chọn giọng Việt Nam nếu có
      const vietnameseVoice = voices.find((voice) =>
        voice.lang.startsWith("vi")
      );
      if (vietnameseVoice) {
        setSelectedVoice(vietnameseVoice);
      } else {
        // Fallback to default voice
        setSelectedVoice(null);
      }
    };

    loadVoices();
    window.speechSynthesis.onvoiceschanged = loadVoices;
  }, []);

  // Cleanup: Dừng TTS khi component unmounts
  useEffect(() => {
    return () => {
      if (window.speechSynthesis.speaking) {
        window.speechSynthesis.cancel();
      }
    };
  }, []);

  const handleImageClick = (image) => {
    setCurrentImage(image);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentImage("");
  };

  /**
   * Hàm loại bỏ hình ảnh và liên kết khỏi markdown
   * Để tránh việc đọc các ký tự của hình ảnh (alt text) và liên kết (URLs)
   */
  const stripMarkdown = (markdown) => {
    return markdown
      .replace(/!\[.*?\]\(.*?\)/g, "") // Loại bỏ hình ảnh
      .replace(/\[([^\]]+)\]\([^\)]+\)/g, "$1") // Loại bỏ liên kết markdown nhưng giữ lại văn bản
      .replace(/https?:\/\/\S+/g, ""); // Loại bỏ các URL dạng plain
  };

  /**
   * Hàm chia nhỏ văn bản thành các câu
   */
  const splitIntoSentences = (text) => {
    // Sử dụng regex để chia nhỏ văn bản thành các câu
    return text.match(/[^\.!\?]+[\.!\?]+/g) || [text];
  };

  /**
   * Hàm bắt đầu đọc bài viết
   */
  const handleSpeak = () => {
    if (!newsItem || !newsItem.description) return;

    // Kiểm tra nếu trình duyệt hỗ trợ Speech Synthesis
    if (!("speechSynthesis" in window)) {
      alert("Trình duyệt của bạn không hỗ trợ chức năng đọc bài viết.");
      return;
    }

    // Nếu đang nói, dừng lại
    if (window.speechSynthesis.speaking) {
      window.speechSynthesis.cancel();
      setIsSpeaking(false);
      setShowSlider(false);
      return;
    }

    // Loại bỏ hình ảnh và liên kết khỏi markdown trước khi đọc
    const plainText = stripMarkdown(newsItem.description);
    const sentences = splitIntoSentences(plainText);
    sentencesRef.current = sentences;
    currentSentenceIndexRef.current = 0;

    // Hàm để đọc từng câu
    const readNextSentence = () => {
      if (currentSentenceIndexRef.current >= sentencesRef.current.length) {
        // Đã đọc xong tất cả các câu
        setIsSpeaking(false);
        setShowSlider(false);
        return;
      }

      const sentence = sentencesRef.current[currentSentenceIndexRef.current];
      const utterance = new SpeechSynthesisUtterance(sentence);
      utterance.lang = "vi-VN"; // Đặt ngôn ngữ tiếng Việt
      utterance.rate = speechRate; // Đặt tốc độ đọc
      utterance.pitch = speechPitch; // Đặt pitch
      utterance.volume = speechVolume; // Đặt volume

      // Chọn voice nếu có
      if (selectedVoice) {
        utterance.voice = selectedVoice;
      }

      // Khi bắt đầu đọc
      utterance.onstart = () => {
        setIsSpeaking(true);
        setShowSlider(true); // Hiển thị thanh trượt khi bắt đầu đọc
      };

      // Khi kết thúc đọc câu hiện tại, đọc câu tiếp theo
      utterance.onend = () => {
        currentSentenceIndexRef.current += 1;
        readNextSentence();
      };

      // Khi có lỗi xảy ra
      utterance.onerror = (e) => {
        console.error("Lỗi khi đọc câu:", e);
        setIsSpeaking(false);
        setShowSlider(false); // Ẩn thanh trượt khi có lỗi
      };

      // Bắt đầu đọc câu
      window.speechSynthesis.speak(utterance);
      utteranceRef.current = utterance;
    };

    // Bắt đầu đọc từ câu đầu tiên
    readNextSentence();
  };

  /**
   * Hàm dừng đọc bài viết
   */
  const handleStop = () => {
    if (window.speechSynthesis.speaking) {
      window.speechSynthesis.cancel();
      setIsSpeaking(false);
      setShowSlider(false);
    }
  };

  /**
   * Hàm xử lý thay đổi tốc độ đọc
   */
  const handleRateChange = (event, newValue) => {
    setSpeechRate(newValue);
    // Cập nhật tốc độ cho các câu tiếp theo
    // Nếu đang đọc, cập nhật tốc độ ngay cho các câu sau
    // Vì không thể thay đổi tốc độ của câu đang đọc
  };

  /**
   * Hàm xử lý thay đổi pitch
   */
  const handlePitchChange = (event, newValue) => {
    setSpeechPitch(newValue);
    // Cập nhật pitch cho các câu tiếp theo
    // Vì không thể thay đổi pitch của câu đang đọc
  };

  /**
   * Hàm xử lý thay đổi volume
   */
  const handleVolumeChange = (event, newValue) => {
    setSpeechVolume(newValue / 100); // Chuyển đổi từ 0-100 sang 0-1
    // Cập nhật volume cho các câu tiếp theo
    // Vì không thể thay đổi volume của câu đang đọc
  };

  if (loading) {
    return (
      <Box sx={{ textAlign: "center", marginTop: "20px" }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!newsItem) {
    return <Typography variant="h6">Không tìm thấy bài viết.</Typography>;
  }

  // Đặt tiêu đề trang và meta thông tin
  const pageTitle = `${newsItem.title} - Tin tức`;
  const pageDescription =
    newsItem.shortIntro || newsItem.description || "Cập nhật tin tức mới nhất";
  const pageKeywords = newsItem.category
    ? `${newsItem.category}, tin tức, cập nhật`
    : "tin tức, cập nhật";

  return (
    <>
      <Helmet>
        {/* SEO Metadata */}
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta name="keywords" content={pageKeywords} />

        {/* Open Graph Metadata */}
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta
          property="og:image"
          content={newsItem.image || "https://via.placeholder.com/300"}
        />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="article" />

        {/* Twitter Card Metadata */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={pageDescription} />
        <meta
          name="twitter:image"
          content={newsItem.image || "https://via.placeholder.com/300"}
        />
        <meta name="twitter:url" content={window.location.href} />
      </Helmet>

      <HighlightHeaderArticle
        category={newsItem.category}
        title={newsItem.title}
        description={newsItem.shortIntro}
        author={newsItem.userId.name}
        userId={newsItem.authorInfo.id}
        date={`${formatDate(newsItem.createdDate)}`}
        time={`${timeAgo(newsItem.createdDate)}`}
        image={newsItem.image}
        bgColor={newsItem.bgColor || "#fff"}
        textColor={newsItem.txtColor || "#000"}
      />

      <Box className="details-news-container">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Box className="details-news-left">
              <Box className="details-news-header">
                <Avatar
                  className="details-news-avatar"
                  src={
                    newsItem.userId?.avatar || "https://via.placeholder.com/150"
                  }
                  alt={newsItem.userId.name || "Unknown"}
                />
                <Box sx={{ ml: 1 }}>
                  <Link
                    to={`/user-wall/${newsItem.authorInfo?.id || "unknown"}`}
                    state={{ userId: newsItem.authorInfo?.id }}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "500",
                        fontSize: "16px",
                        textTransform: "uppercase",
                      }}
                    >
                      {newsItem.userId.name}
                    </Typography>
                  </Link>
                  <Typography
                    sx={{
                      mb: 1,
                      background:
                        "linear-gradient(45deg, #ff7a18,rgb(215, 239, 107),rgb(9, 142, 53))",
                      WebkitBackgroundClip: "text",
                      WebkitTextFillColor: "transparent",
                      fontWeight: 400,
                      fontSize: "10px",
                    }}
                  >
                    {newsItem.userId.personal_title || "Không rõ tác giả"}
                  </Typography>
                  <Typography sx={{ fontSize: "0.828rem", color: "#92abc8" }}>
                    {timeAgo(newsItem.createdDate)}
                  </Typography>
                </Box>
              </Box>

              <Typography className="details-news-title" variant="h5">
                {newsItem.title}
              </Typography>

              <Box className="details-news-slider-wrapper" sx={{ mt: 0 }}>
                <ImageSlider
                  images={newsItem.images}
                  onImageClick={handleImageClick}
                />
              </Box>

              {/* Hiển thị video nếu có */}
              {newsItem.videoReview && (
                <>
                  <Box className="details-news-video" sx={{ mt: 2 }}>
                    <ReactPlayer
                      url={newsItem.videoReview}
                      controls
                      playing={false} // Đặt true nếu muốn tự động phát
                      width="100%"
                      height="360px"
                    />
                  </Box>
                </>
              )}

              <Box className="details-news-content" sx={{ mt: 1 }}>
                <ReactMarkdown>{newsItem.description}</ReactMarkdown>
              </Box>
              
            </Box>
          </Grid>
        </Grid>

        {/* Dialog để hiển thị hình ảnh fullscreen */}
        <Dialog fullScreen open={open} onClose={handleClose}>
          <DialogContent>
            <IconButton
              className="dialog-close-button"
              edge="end"
              sx={{ position: "absolute", top: 10, right: 30 }}
              onClick={handleClose}
            >
              <Close />
            </IconButton>
            <img src={currentImage} alt="Fullscreen" className="dialog-image" />
          </DialogContent>
        </Dialog>
      </Box>

      {/* Floating Read Aloud Tool */}
      <Box className="floating-read-aloud-tool">
        <Tooltip title={isSpeaking ? "Dừng đọc" : "Đọc bài viết"}>
          <IconButton
            onClick={isSpeaking ? handleStop : handleSpeak}
            aria-label="read-aloud"
            className="floating-read-aloud-button"
          >
            {isSpeaking ? <Pause /> : <PlayArrow />}
          </IconButton>
        </Tooltip>
        {/* Thanh trượt tốc độ đọc */}
        {showSlider && (
          <Box className="floating-speech-rate-slider">
            <Typography className="floating-speech-rate-label">
              Tốc độ:
            </Typography>
            <Slider
              value={speechRate}
              min={0.5}
              max={2}
              step={0.1}
              onChange={handleRateChange}
              valueLabelDisplay="auto"
              aria-labelledby="speech-rate-slider"
            />
          </Box>
        )}
        {/* Thanh trượt pitch */}
        {/* {showSlider && (
          <Box className="floating-speech-pitch-slider">
            <Typography className="floating-speech-pitch-label">Pitch:</Typography>
            <Slider
              value={speechPitch}
              min={0.5}
              max={2}
              step={0.1}
              onChange={handlePitchChange}
              valueLabelDisplay="auto"
              aria-labelledby="speech-pitch-slider"
            />
          </Box>
        )} */}
        {/* Thanh trượt volume */}
        {/* {showSlider && (
          <Box className="floating-speech-volume-slider">
            <Typography className="floating-speech-volume-label">Volume:</Typography>
            <Slider
              value={speechVolume * 100} // Chuyển đổi từ 0-1 sang 0-100
              min={0}
              max={100}
              step={1}
              onChange={handleVolumeChange}
              valueLabelDisplay="auto"
              aria-labelledby="speech-volume-slider"
            />
          </Box>
        )} */}
        {/* Nút tắt công cụ đọc */}
        {/* {showSlider && (
          <Tooltip title="Đóng công cụ đọc">
            <IconButton
              onClick={() => setShowSlider(false)}
              aria-label="close-read-aloud-tool"
              className="floating-close-read-aloud-tool-button"
            >
              <Close />
            </IconButton>
          </Tooltip>
        )} */}
      </Box>
    </>
  );
};

export default DetailsNews;
