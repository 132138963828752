import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import apiClient from "../api/apiClient";
import endpoints from "../api/endpoints";
import "../styles/Categories.css";
import { Helmet } from "react-helmet-async";

const CategoriesPage = () => {
  const [categories, setCategories] = useState([]); // Lưu dữ liệu chuyên mục
  const [loading, setLoading] = useState(true); // Trạng thái tải dữ liệu
  const [error, setError] = useState(null); // Lỗi nếu có
  const isInitialMount = useRef(true); // Biến cờ để kiểm tra lần đầu mount

  // Gọi API để lấy danh sách chuyên mục
  const fetchCategories = async () => {
    try {
      const response = await apiClient.get(endpoints.discussionCategory.list);
      setCategories(response.data); // Lưu danh sách chuyên mục vào state
      setLoading(false);
    } catch (error) {
      setError("Không thể tải chuyên mục. Vui lòng thử lại!");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isInitialMount.current) {
      fetchCategories(); // Gọi API chỉ trong lần mount đầu tiên
      isInitialMount.current = false; // Đánh dấu là đã gọi API
    }
  }, []); // Chỉ chạy khi component mount

  if (loading) {
    return <div className="categories-news-loading">Đang tải...</div>;
  }

  if (error) {
    return <div className="categories-news-error">{error}</div>;
  }

  return (
    <div className="categories-container">
       <Helmet>
        <title>Mua Bán & Rao Vặt | Genyz.vn</title>
        <meta
          name="description"
          content="Khám phá các chuyên mục về bán hàng, rao vặt, và các topic thú vị khác. Cập nhật những tin tức mới nhất, nhanh chóng và đáng tin cậy tại Genyz.vn."
        />
        <meta property="og:title" content="Mua Bán & Rao Vặt | Genyz.vn" />
        <meta
          property="og:description"
          content="Khám phá các chuyên mục bán hàng, rao vặt cùng hàng loạt topic hữu ích khác. Tham gia cộng đồng Genyz.vn ngay hôm nay để không bỏ lỡ thông tin hot nhất!"
        />
        <meta
          property="og:image"
          content={categories[0]?.image || "https://via.placeholder.com/600"}
        />
      </Helmet>
      
      <h1 className="categories-title">Chuyên Mục</h1>

      <div className="categories-grid">
        {categories.map((category, index) => (
          <div key={`${category.id} - ${index}`} className="category-item">
            <Link to={`/categories/${category._id}`} className="category-link">
              <h2 className="category-name">{category.title}</h2>
            </Link>
            <p className="category-info">
              Threads: <span>{category.threadCount}</span> | Messages:{" "}
              <span>{category.commentCount}</span>
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CategoriesPage;
