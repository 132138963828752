import React, { useEffect } from "react";
import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";
import {
  Home,
  Notifications,
  // AddCircle,
  Person,
  CenterFocusStrong,
  TipsAndUpdates,
} from "@mui/icons-material";
import { useNavigate, useLocation } from "react-router-dom";

const BottomNavigationBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = React.useState(0);

  // Synchronize the value with the current route
  useEffect(() => {
    const pathToValue = {
      "/home": 0,
      "/user-wall": 0,

      "/news": 1,
      "/tips-and-tricks": 2,

      "/categories": 3,

      "/profile": 4,
      "/login": 4,
      "/register": 4,
      "/reset-password": 4,
    };
    setValue(pathToValue[location.pathname]);
  }, [location.pathname]);

  const handleNavigation = (newValue) => {
    if (newValue === 0) navigate("/home");
    else if (newValue === 1) navigate("/news");
    else if (newValue === 2) navigate("/tips-and-tricks");
    else if (newValue === 3) navigate("/categories");
    else if (newValue === 4) {
      const token = localStorage.getItem("access_token");
      if (token) {
        navigate("/profile");
      } else {
        navigate("/login");
      }
    }
  };

  return (
    <Paper
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1,
        backgroundColor: "#ffffff",
        borderRadius: "12px 12px 0 0",
        display: { xs: "block", md: "none" }, // Display only on mobile
      }}
      elevation={3}
    >
      <BottomNavigation
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
          handleNavigation(newValue);
        }}
        sx={{
          height: "4.3682rem",
          backgroundColor: "#ffffff",
        }}
      >
        <BottomNavigationAction
          label=""
          icon={<Home />}
          sx={{
            // color: value === 0 ? "#1782ff" : "#091827",
            color:
              value === 0 ||
              location.pathname.startsWith("/posts") ||
              location.pathname.startsWith("/user-wall")
                ? "#1782ff"
                : "#091827",
          }}
        />
        <BottomNavigationAction
          label=""
          icon={<Notifications />}
          sx={{
            // color: value === 1 ? "#1782ff" : "#091827",
            color:
              value === 1 || location.pathname.startsWith("/news")
                ? "#1782ff"
                : "#091827",
          }}
        />

        <BottomNavigationAction
          label=""
          icon={<TipsAndUpdates />}
          sx={{
            // color: value === 2 ? "#1782ff" : "#091827",
            color:
              value === 2 || location.pathname.startsWith("/tips-and-tricks")
                ? "#1782ff"
                : "#091827",
          }}
        />

        <BottomNavigationAction
          label=""
          icon={<CenterFocusStrong />}
          sx={{
            // color: value === 3 ? "#1782ff" : "#091827",
            color:
              value === 3 || location.pathname.startsWith("/categories")
                ? "#1782ff"
                : "#091827",
          }}
        />

        <BottomNavigationAction
          label=""
          icon={<Person />}
          sx={{
            color: value === 4 ? "#1782ff" : "#091827",
            // color: value === 3 || location.pathname.startsWith("/user-wall") ? "#1782ff" : "#091827",
          }}
        />
        {/* <BottomNavigationAction
          icon={
            <AddCircle
              sx={{
                fontSize: "36px",
                color: "#1782ff",
              }}
            />
          }
          sx={{
            "& .MuiBottomNavigationAction-label": {
              display: "none",
            },
          }}
        /> */}
      </BottomNavigation>
    </Paper>
  );
};

export default BottomNavigationBar;
