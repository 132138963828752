import React, {
  useEffect,
  useRef,
  useCallback,
  useState,
  useContext,
} from "react";
import {
  Box,
  Typography,
  CircularProgress,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { useShortNews } from "../context/ShortNewsContext";
import { UserContext } from "../context/UserContext";
import { formatDate, timeAgo } from "../utils/dateUtils";
import DeleteIcon from "@mui/icons-material/Delete";
import "../styles/News.css";

const ShortNews = () => {
  const { newsList, loading, error, hasMore, page, setPage, deleteNews } =
    useShortNews();
  const { user } = useContext(UserContext);

  const isAdmin = user?.isAdmin; // Kiểm tra quyền admin
  const observerRef = useRef(null);

  // State cho dialog xóa
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [newsToDelete, setNewsToDelete] = useState(null);

  const handleDeleteOpen = (id) => {
    setNewsToDelete(id);
    setOpenDeleteDialog(true);
  };

  const handleDeleteClose = () => {
    setNewsToDelete(null);
    setOpenDeleteDialog(false);
  };

  const handleConfirmDelete = async () => {
    if (newsToDelete) {
      await deleteNews(newsToDelete);
      handleDeleteClose();
    }
  };

  const handleObserver = useCallback(
    (entries) => {
      if (entries[0].isIntersecting && hasMore && !loading) {
        setPage((prevPage) => prevPage + 1);
      }
    },
    [hasMore, loading, setPage]
  );

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, {
      threshold: 0.1,
    });
    const currentRef = observerRef.current;
    if (currentRef) {
      observer.observe(currentRef);
    }
    return () => {
      if (currentRef) observer.unobserve(currentRef);
    };
  }, [handleObserver]);

  const truncateText = (text, maxLength) =>
    text?.length > maxLength ? `${text.slice(0, maxLength)}...` : text;

  if (loading && newsList.length === 0) {
    return (
      <Box sx={{ textAlign: "center", padding: "24px" }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ textAlign: "center", padding: "24px", color: "red" }}>
        {error}
      </Box>
    );
  }

  return (
    <Box className="short-news-container">
      <Helmet>
        <title>Tin tức ngắn | Genyz.vn</title>
        <meta
          name="description"
          content="Cập nhật những tin tức ngắn, nổi bật, và mới nhất từ nhiều lĩnh vực."
        />
        <meta property="og:title" content="Tin tức ngắn | Genyz.vn" />
        <meta
          property="og:description"
          content="Cập nhật những tin tức ngắn, nổi bật, và mới nhất từ nhiều lĩnh vực."
        />
        <meta
          property="og:image"
          content={newsList[0]?.image || "https://via.placeholder.com/600"}
        />
      </Helmet>

      {/* Tin nổi bật */}
      {newsList.length > 0 && (
        <Link
          to={`/news/${newsList[0].slug}`}
          state={{ id: newsList[0]._id }}
          style={{ textDecoration: "none" }}
          className="short-news-featured"
        >
          <img
            src={newsList[0].image || "https://via.placeholder.com/600"}
            alt={newsList[0].title || "Không có tiêu đề"}
            className="short-news-featured-image"
          />
          <div className="short-news-featured-content">
            <span className="short-news-featured-category">
              {newsList[0].category || "Uncategorized"}
            </span>
            <h2 className="short-news-featured-title">
              {newsList[0].title || "Không có tiêu đề"}
            </h2>
            <p className="short-news-featured-description">
              {truncateText(newsList[0].description, 120)}
            </p>
            <p>
              <span className="short-news-featured-author">
                {newsList[0].userId?.name || "Không rõ tác giả"}
              </span>
              <span className="short-news-featured-meta">
                {formatDate(newsList[0].createdDate)}
              </span>
              <span className="short-news-featured-meta">
                {timeAgo(newsList[0].createdDate)}
              </span>
            </p>
          </div>
        </Link>
      )}

      {/* Danh sách tin */}
      <Box className="short-news-list">
        {newsList.slice(1).map((newsItem, index) => (
          <Box
            key={`${newsItem._id}-${index}`}
            style={{ position: "relative" }}
          >
            {isAdmin && (
              <Box className="short-news-delete-wrapper">
                <IconButton
                  sx={{
                    position: "absolute",
                    top: "-8%",
                    right: "-2%",
                    background: "red",
                    color: "#fff",
                    zIndex: 99
                  }}
                  onClick={() => handleDeleteOpen(newsItem._id)}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            )}
            <Link
              to={`/news/${newsItem.slug}`}
              state={{ id: newsItem._id }}
              style={{ textDecoration: "none" }}
              className="short-news-item"
            >
              <span className="short-news-item-category">
                {newsItem.category || "Uncategorized"}
              </span>
              <Box className="short-news-item-content">
                <Typography variant="h6" className="short-news-item-title">
                  {newsItem.title || "Không có tiêu đề"}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "0.8rem",
                    color: "gray",
                    textTransform: "uppercase",
                  }}
                >
                  {newsItem.userId?.name || "Không rõ tác giả"} -{" "}
                  {formatDate(newsItem.createdDate)} •{" "}
                  {timeAgo(newsItem.createdDate)}
                </Typography>
              </Box>
              <img
                src={newsItem.image || "https://via.placeholder.com/150"}
                alt={newsItem.title || "Không có tiêu đề"}
                className="short-news-item-image"
              />
            </Link>
          </Box>
        ))}
      </Box>

      {/* Dialog xóa */}
      <Dialog open={openDeleteDialog} onClose={handleDeleteClose}>
        <DialogTitle>Xác Nhận Xóa</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Bạn có chắc chắn muốn xóa bài viết này? Hành động này không thể hoàn
            tác.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteClose} color="primary">
            Hủy
          </Button>
          <Button onClick={handleConfirmDelete} color="error">
            Xóa
          </Button>
        </DialogActions>
      </Dialog>

      {loading && hasMore && (
        <Box sx={{ textAlign: "center", padding: "20px" }}>
          <CircularProgress />
        </Box>
      )}

      <div ref={observerRef} style={{ height: 1 }} />

      {!hasMore && (
        <Box sx={{ textAlign: "center", padding: "8px", color: "gray" }}>
          <Typography variant="body2">Bạn đã xem hết tin hiện có!</Typography>
        </Box>
      )}
    </Box>
  );
};

export default ShortNews;
