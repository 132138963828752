import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import apiClient from "../api/apiClient";
import endpoints from "../api/endpoints";

// Tạo Context
const ShortNewsContext = createContext();

// Custom hook để tiêu thụ context
export const useShortNews = () => {
  return useContext(ShortNewsContext);
};

/**
 * Provider để bọc xung quanh App / Router
 *
 * Mục đích:
 *  - Lưu trữ danh sách tin (newsList)
 *  - Tải dữ liệu từ server (fetchShortNews)
 *  - Quản lý logic phân trang (page, hasMore, loading, vv.)
 *  - Quản lý caching ID (nếu cần tránh trùng lặp)
 */
export const ShortNewsProvider = ({ children }) => {
  const [newsList, setNewsList] = useState([]);
  const [loading, setLoading] = useState(true); // Lần đầu load
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1); // Trang hiện tại
  const [hasMore, setHasMore] = useState(true); // Còn dữ liệu để tải không
  const [isFetching, setIsFetching] = useState(false); // Đang fetch trang hiện tại
  const [totalItems, setTotalItems] = useState(0); // Tổng số item (nếu API trả về)

  // Cache ID để tránh trùng lặp tin (nếu server có thể trả về item đã có)
  const cachedNewsIds = useRef(new Set());

  /**
   * Hàm fetch dữ liệu
   * @param {number} currentPage - Trang muốn tải
   * @param {number} limit       - Số item / trang
   */
  const fetchShortNews = useCallback(
    async (currentPage, limit = 10) => {
      // Tránh gọi API chồng chéo
      if (isFetching || !hasMore) return;

      setIsFetching(true);
      setLoading(true);

      try {
        // Gọi API (ví dụ: endpoints.shortNews.list trả về "/short-news?page=X&limit=Y")
        const response = await apiClient.get(
          endpoints.shortNews.list(currentPage, limit)
        );

        // Giả sử response.data có dạng { data: [], total: number }
        const dataArr = Array.isArray(response.data.data)
          ? response.data.data
          : [];
        const total = response.data.total || 0;
        setTotalItems(total);

        // 1) Page = 1 => reset danh sách
        if (currentPage === 1) {
          setNewsList(dataArr);
          // Nếu số item trả về < total => còn nữa
          setHasMore(dataArr.length < total);

          // Reset cache ID
          cachedNewsIds.current.clear();
          dataArr.forEach((item) => cachedNewsIds.current.add(item._id));
        }
        // 2) Page > 1 => nối vào
        else {
          // Lọc trùng nếu cần
          const filteredData = dataArr.filter(
            (item) => !cachedNewsIds.current.has(item._id)
          );
          filteredData.forEach((item) => cachedNewsIds.current.add(item._id));

          setNewsList((prev) => {
            const updated = [...prev, ...filteredData];
            // Kiểm tra còn item để load không
            setHasMore(updated.length < total);
            return updated;
          });
        }

        // Nếu trả về rỗng => hết data
        if (!dataArr || dataArr.length === 0) {
          setHasMore(false);
        }
      } catch (err) {
        console.error("Error fetching short news:", err);
        setError("Unable to load news. Please try again later!");
        setHasMore(false); // Tắt luôn hasMore để khỏi lặp vô hạn
      } finally {
        setLoading(false);
        setIsFetching(false);
      }
    },
    [isFetching, hasMore]
  );

  // Xóa bài viết
  const deleteNews = useCallback(async (newsId) => {
    try {
      await apiClient.delete(endpoints.shortNews.delete(newsId));
      setNewsList((prev) => prev.filter((news) => news._id !== newsId));
      cachedNewsIds.current.delete(newsId);
    } catch (err) {
      console.error("Error deleting news:", err);
    }
  }, []);

  /**
   * Mỗi khi `page` thay đổi => gọi fetch
   */
  useEffect(() => {
    fetchShortNews(page, 10);
    // eslint-disable-next-line
  }, [page]);

  return (
    <ShortNewsContext.Provider
      value={{
        newsList,
        loading, // Có thể xài loading này để hiển thị spinner (lần đầu)
        error, // Lỗi
        hasMore, // true/false => còn data load không
        page, // Trang hiện tại
        setPage, // Hàm setPage => triggers fetch
        totalItems, // Tổng item
        isFetching, // Đang fetch
        fetchShortNews, // Để manual call nếu cần
        deleteNews,
      }}
    >
      {children}
    </ShortNewsContext.Provider>
  );
};
