import React from "react";
import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import "../styles/HighlightHeaderArticle.css";

const HighlightHeaderArticle = ({
  category,
  title,
  description,
  author,
  userId,
  date,
  time,
  image,
  bgColor,
  textColor,
  commentCount,
}) => {
  return (
    <Box className="highlight-container">
      <Box
        className="highlight-header"
        style={{
          backgroundColor: bgColor,
          color: textColor,
        }}
      >
        {/* Image Section */}
        <Box
          className="header-image"
          style={{
            backgroundImage: `url(${image})`,
          }}
        />

        {/* Content Section */}
        <Box className="header-content">
          {/* Category */}
          <Typography className="header-category">{category}</Typography>

          {/* Title */}
          <Typography className="header-title">{title}</Typography>

          {/* Description */}
          <Typography className="header-description">{description}</Typography>

          {/* Author and Date */}
          <Link
            to={`/user-wall/${userId || "unknown"}`}
            state={{ userId: userId }}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <Typography className="header-author">
              {author} • {date} • {time}
            </Typography>
          </Link>

          {/* Footer Icons */}
          <Box className="header-footer-icons">
            {/* <Box className="icon">&#x1F517;</Box> */}
            {/* <Box className="icon">&#x1F4F1;</Box> */}
            {/* <Box className="icon">&#x1F4AC;</Box>
            <Typography className="comments">
              {commentCount} Bình luận{" "}
            </Typography> */}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default HighlightHeaderArticle;
