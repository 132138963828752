import React, { useEffect, useState, useRef, useCallback } from "react";
import { useParams, Link } from "react-router-dom";
import { Box, Typography, Avatar, Card, CardContent, Divider, CircularProgress } from "@mui/material";
import apiClient from "../api/apiClient";
import endpoints from "../api/endpoints"; 
import ImageSlider from "../components/ImageSlider"; 
import "../styles/UserWall.css";
import { formatDate, timeAgo } from "../utils/dateUtils";

const UserWall = () => {
  const { userId } = useParams();
  
  // Danh sách bài viết, trang hiện tại, v.v.
  const [userPosts, setUserPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  // Trạng thái loading, lỗi, và còn bài để tải (hasMore)
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [hasMore, setHasMore] = useState(true);

  // Tổng số bài, like, comment
  const [totalPosts, setTotalPosts] = useState(0);
  const [totalLikes, setTotalLikes] = useState(0);
  const [totalComments, setTotalComments] = useState(0);

  // Ref cho IntersectionObserver (để infinite scroll)
  const observerRef = useRef(null);

  /**
   * Gọi API để lấy bài viết (theo trang)
   * Nếu trang = 1 => reset userPosts
   * Nếu trang > 1 => nối thêm bài
   */
  const fetchUserPosts = useCallback(async () => {
    setIsLoading(true);

    try {
      const response = await apiClient.get(
        `${endpoints.news.list}/user/${userId}?page=${currentPage}&limit=10`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      const dataArr = response.data.data || [];
      const total = response.data.total || 0;

      // Nếu currentPage === 1 => reset
      if (currentPage === 1) {
        setUserPosts(dataArr);
      } else {
        // Nối thêm
        setUserPosts((prev) => [...prev, ...dataArr]);
      }

      // Tính tổng
      setTotalPosts(total);
      setTotalLikes(dataArr.reduce((sum, post) => sum + post.likeCount, 0));
      setTotalComments(dataArr.reduce((sum, post) => sum + post.commentCount, 0));

      // Kiểm tra còn bài hay không
      // Nếu length < limit => hết bài
      if (dataArr.length < 10) {
        setHasMore(false);
      } else {
        setHasMore(true);
      }
    } catch (err) {
      setError("Không thể tải bài viết của người dùng.");
    } finally {
      setIsLoading(false);
    }
  }, [userId, currentPage]);

  /**
   * Gọi fetchUserPosts mỗi khi userId hoặc currentPage thay đổi
   */
  useEffect(() => {
    fetchUserPosts();
  }, [fetchUserPosts]);

  /**
   * IntersectionObserver callback
   * Khi div ref={observerRef} vào vùng nhìn thấy => nếu còn bài (hasMore) & không loading => setCurrentPage +1
   */
  const handleObserver = useCallback((entries) => {
    if (!entries[0].isIntersecting) return; 
    if (hasMore && !isLoading) {
      setCurrentPage((prev) => prev + 1);
    }
  }, [hasMore, isLoading]);

  /**
   * Tạo IntersectionObserver
   */
  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, { threshold: 0.5 });
    const currentRef = observerRef.current;
    if (currentRef) {
      observer.observe(currentRef);
    }
    return () => {
      if (currentRef) observer.unobserve(currentRef);
    };
  }, [handleObserver]);

  /**
   * Hiển thị loading khi trang đầu
   */
  if (isLoading && currentPage === 1 && userPosts.length === 0) {
    return (
      <Box sx={{ textAlign: "center", padding: "20px", color: "#333" }}>
        <Typography>Đang tải...</Typography>
      </Box>
    );
  }

  /**
   * Hiển thị lỗi
   */
  if (error) {
    return (
      <Box sx={{ textAlign: "center", padding: "20px", color: "#ff0000" }}>
        <Typography>{error}</Typography>
      </Box>
    );
  }

  return (
    <Box className="user-wall">
      {/** Header - chỉ hiển thị nếu có ít nhất 1 bài */}
      {userPosts.length > 0 && (
        <Box className="wall-header">
          <Avatar
            src={userPosts[0]?.userId?.avatar || "https://via.placeholder.com/100"}
            alt="User Avatar"
            className="user-avatar"
          />
          <Typography variant="h5" className="user-name">
            {userPosts[0]?.userId?.name || "Unknown User"}
          </Typography>
          <Typography className="details-user-wall-avatar">
            {userPosts[0]?.userId?.personal_title || ""}
          </Typography>
          <Typography variant="body2" className="user-info">
            Bài đã đăng: <strong>{totalPosts}</strong> | 
            Lượt thích: <strong>{totalLikes}</strong> | 
            Lượt bình luận: <strong>{totalComments}</strong>
          </Typography>
        </Box>
      )}

      <Divider sx={{ marginY: "20px", borderTop: "2px solid #92abc8" }} />

      {/* Danh sách bài viết */}
      <Box>
        {userPosts.length > 0 ? (
          userPosts.map((post) => (
            <Card key={post.id} className="post-card">
              <CardContent>
                <Box className="post-header">
                  <Avatar
                    src={post.userId.avatar || "https://via.placeholder.com/150"}
                    alt={post.userId.name || "Unknown"}
                    className="post-avatar"
                  />
                  <Box>
                    <Typography className="post-author">{post.userId.name}</Typography>
                    <Typography className="post-date">
                      {formatDate(post.createdDate)} - {timeAgo(post.createdDate)}
                    </Typography>
                  </Box>
                </Box>
                <Link
                  to={`/posts/${post.slug}`}
                  state={{ id: post.id }}
                  style={{ textDecoration: "none" }}
                >
                  <Typography variant="h6" className="post-title">
                    {post.title}
                  </Typography>
                </Link>
                <Typography variant="body2" className="post-content">
                  {post.shortIntro}
                </Typography>
              </CardContent>
              {/* Image Slider */}
              <ImageSlider images={post.images || []} />
            </Card>
          ))
        ) : (
          <Typography variant="body2" className="no-posts">
            Người dùng chưa có bài viết nào.
          </Typography>
        )}
      </Box>

      {/* Spinner nhỏ cuối trang khi đang loading trang tiếp (và vẫn còn bài) */}
      {isLoading && hasMore && (
        <Box sx={{ textAlign: "center", marginY: "20px" }}>
          <CircularProgress />
        </Box>
      )}

      <Divider sx={{ marginY: "20px", borderTop: "2px solid #92abc8" }} />

      {/* Div “chạm đáy” để IntersectionObserver bám vào */}
      <div ref={observerRef} style={{ height: "1px" }} />

    </Box>
  );
};

export default UserWall;
