import React, { useState, useEffect, useRef, useCallback } from "react";
import { Link } from "react-router-dom";
import { Box, CircularProgress } from "@mui/material";
import apiClient from "../api/apiClient";
import endpoints from "../api/endpoints";
import "../styles/TipsAndTricks.css";
import { formatDate, timeAgo } from "../utils/dateUtils";
import { Helmet } from "react-helmet-async";

const TipsAndTricks = () => {
  const [tipsData, setTipsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // infinite scroll states
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isFetching, setIsFetching] = useState(false);

  // Ref để chặn gọi fetch nhiều lần lúc mount
  // => Hoặc có thể bỏ, vì ta đã dùng page=1
  const isInitialMount = useRef(true);

  // Ref cho IntersectionObserver - gắn vào 1 <div> cuối trang
  const observerRef = useRef(null);

  /**
   * Gọi API để lấy tips
   */
  const fetchTips = useCallback(async (pageNum) => {
    setIsFetching(true);
    try {
      const response = await apiClient.get(
        endpoints.tipsAndTricksEndpoints.list,
        {
          params: { page: pageNum, limit: 10 },
        }
      );
      const dataArr = Array.isArray(response.data.data)
        ? response.data.data
        : [];

      // Nối data vào state
      setTipsData((prev) => [...prev, ...dataArr]);

      // Nếu số item trả về < 10 => hết dữ liệu
      if (dataArr.length < 10) {
        setHasMore(false);
      }
    } catch (err) {
      console.error("Lỗi khi lấy dữ liệu Tips & Tricks:", err);
      setError("Không thể tải dữ liệu. Vui lòng thử lại sau!");
    } finally {
      setLoading(false);
      setIsFetching(false);
    }
  }, []);

  /**
   * Gọi fetchTips lần đầu (page=1)
   */
  useEffect(() => {
    if (isInitialMount.current) {
      fetchTips(page);
      isInitialMount.current = false;
    }
  }, [fetchTips, page]);

  /**
   * IntersectionObserver callback
   * Khi thẻ ref={observerRef} vào vùng nhìn thấy => nếu còn data & không fetch => setPage +1
   */
  const handleObserver = useCallback(
    (entries) => {
      if (!entries[0].isIntersecting) return;
      // Nếu còn bài (hasMore) & không đang fetch => load thêm
      if (hasMore && !isFetching) {
        setPage((prev) => prev + 1);
      }
    },
    [hasMore, isFetching]
  );

  /**
   * Tạo IntersectionObserver
   */
  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, {
      root: null,
      threshold: 0.5, // 50% ref vào viewport
    });
    const currentRef = observerRef.current;
    if (currentRef) observer.observe(currentRef);

    return () => {
      if (currentRef) observer.unobserve(currentRef);
    };
  }, [handleObserver]);

  /**
   * Hiển thị Loading lần đầu (nếu chưa có dữ liệu)
   */
  if (loading && page === 1 && tipsData.length === 0) {
    return <div className="tips-loading">Đang tải...</div>;
  }

  /**
   * Nếu có lỗi
   */
  if (error) {
    return <div className="tips-error">{error}</div>;
  }

  /**
   * Nếu không có lỗi, không loading => Kiểm tra có data không
   */
  if (!loading && tipsData.length === 0) {
    return <div className="tips-no-data">Không có Tips & Tricks nào.</div>;
  }

  return (
    <div className="tips-container">
      <Helmet>
        <title>Mẹo vặt | Genyz.vn</title>
        <meta
          name="description"
          content="Cập nhật những mẹo vặt, thủ thuật, ứng dụng hay trong cuộc sống."
        />
        <meta property="og:title" content="Mẹo vặt | Genyz.vn" />
        <meta
          property="og:description"
          content="Cập nhật những mẹo vặt, thủ thuật, ứng dụng hay trong cuộc sống."
        />
        <meta
          property="og:image"
          content={tipsData[0]?.image || "https://via.placeholder.com/600"}
        />
      </Helmet>
      <h1 className="tips-title">Tips & Tricks</h1>
      <div className="tips-grid">
        {tipsData.map((tip) => (
          <div key={tip._id} className="tip-item">
            <img
              src={tip.image || "https://via.placeholder.com/150"}
              alt={tip.title || "No Title"}
              className="tip-image"
            />
            <div className="tip-content">
              <h3>
                <Link to={`/tips-and-tricks/${tip._id}`} className="tip-link">
                  {tip.title || "No Title"}
                </Link>
              </h3>
              <p className="tip-meta">
                {tip.userId?.name || "Unknown"} - {formatDate(tip.createdDate)}{" "}
                • {timeAgo(tip.createdDate)}
              </p>
              <p className="tip-summary">
                {tip.shortIntroduce || "No Summary"}
              </p>
            </div>
          </div>
        ))}
      </div>

      {/* Spinner nhỏ cuối trang khi đang loading trang tiếp (và còn data) */}
      {isFetching && hasMore && (
        <Box sx={{ textAlign: "center", marginY: "20px" }}>
          <CircularProgress />
        </Box>
      )}

      {/* Thẻ div “chạm đáy” để IntersectionObserver bám vào */}
      <div ref={observerRef} style={{ height: 1 }} />
    </div>
  );
};

export default TipsAndTricks;
