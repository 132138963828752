// src/components/VerifyEmail.js

import React, { useState } from "react";
import {
  CircularProgress,
  TextField,
  Button,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import apiClient from "../api/apiClient";
import "../styles/VerifyEmail.css"; // Import file CSS

const VerifyEmail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const email = location.state?.email || "";
  const [verificationCode, setVerificationCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [successOpen, setSuccessOpen] = useState(false);

  const handleVerifyEmail = async () => {
    setLoading(true);
    setError("");
    try {
      await apiClient.post("/users/verify-email", { email, verificationCode });
      setSuccessOpen(true);
      setTimeout(() => {
        navigate("/profile", { replace: true });
        window.location.reload(); // Reload lại trang profile
      }, 2000); // Hiển thị thông báo trong 2 giây trước khi chuyển trang
    } catch (error) {
      console.error("Lỗi khi xác thực email:", error);
      setError("Mã xác thực không chính xác hoặc đã hết hạn.");
    } finally {
      setLoading(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSuccessOpen(false);
  };

  return (
    <div className="verify-email-container">
      <Typography variant="h5" className="verify-email-title">
        NHẬP MÃ XÁC THỰC
      </Typography>
      <div className="verify-email-info">
        <Typography>
          Nhập mã xác nhận đã được gửi đến email của bạn:
        </Typography>
        <Typography className="verify-email-info-email">
          {email}
        </Typography>
        <TextField
          label="Mã xác thực"
          value={verificationCode}
          onChange={(e) => {
            const value = e.target.value;
            if (/^\d*$/.test(value) && value.length <= 6) {
              setVerificationCode(value);
            }
          }}
          className="verify-email-textfield"
          variant="outlined"
          InputProps={{
            style: {
              color: "#fff", // Màu chữ
              backgroundColor: "#2d3748", // Màu nền
              borderRadius: "4px",
              fontSize: "18px", // Font chữ to hơn
            },
          }}
          InputLabelProps={{
            style: { fontSize: "16px", color: "#fff" }, // Tăng font chữ của label và màu
          }}
          inputProps={{ maxLength: 6 }} // Giới hạn độ dài tối đa
        />
        {error && <Typography className="verify-email-error">{error}</Typography>}
      </div>

      <Button
        variant="contained"
        color="primary"
        onClick={handleVerifyEmail}
        disabled={loading || !verificationCode}
        className="verify-email-button"
      >
        {loading ? <CircularProgress size={24} color="inherit" /> : "Xác thực"}
      </Button>

      {/* Snackbar Hiển Thị Thông Báo Thành Công */}
      <Snackbar
        open={successOpen}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: "100%" }}
        >
          Xác thực email thành công!
        </Alert>
      </Snackbar>
    </div>
  );
};

export default VerifyEmail;
