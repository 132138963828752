import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Box,
  Typography,
  Avatar,
  CircularProgress,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import { Link } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import { Helmet } from "react-helmet-async";

import { NewsContext } from "../context/NewsContext";
import { UserContext } from "../context/UserContext"; // <-- Import UserContext

import "../styles/Home.css";
import { formatDate, timeAgo } from "../utils/dateUtils";
import DeleteIcon from "@mui/icons-material/Delete";

const Home = () => {
  // Lấy state và hàm từ NewsContext (quản lý danh sách tin, infinite scroll)
  const { newsList, loading, hasMore, page, setPage, deleteNews } =
    useContext(NewsContext);

  // Lấy user từ UserContext
  const { user } = useContext(UserContext);
  // Chỉ admin mới được phép xóa
  const isAdmin = user?.isAdmin === true;

  const [pendingObserver, setPendingObserver] = useState(false);
  const observerRef = useRef(null);

  // State cho Dialog xác nhận xóa
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [newsToDelete, setNewsToDelete] = useState(null);

  // Khi vào trang lần đầu => setPage(1)
  useEffect(() => {
    setPage(1);
    // eslint-disable-next-line
  }, []);

  // IntersectionObserver callback
  const handleObserver = (entries) => {
    if (!entries[0].isIntersecting) return;
    if (hasMore && !loading && !pendingObserver) {
      setPendingObserver(true);
      setPage((prev) => prev + 1);
      setTimeout(() => {
        setPendingObserver(false);
      }, 1000);
    }
  };

  // Tạo IntersectionObserver (threshold = 1.0 => chạm đáy thật sự)
  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, {
      root: null,
      rootMargin: "0px",
      threshold: 1.0,
    });
    if (observerRef.current) observer.observe(observerRef.current);
    return () => {
      if (observerRef.current) observer.unobserve(observerRef.current);
    };
  }, [hasMore, loading]);

  // Loading lần đầu, chưa có tin => hiển thị spinner to
  if (loading && newsList.length === 0) {
    return (
      <Box sx={{ textAlign: "center", padding: "24px" }}>
        <CircularProgress />
      </Box>
    );
  }

  // Mở dialog xóa
  const handleDeleteOpen = (id) => {
    setNewsToDelete(id);
    setOpenDeleteDialog(true);
  };

  // Đóng dialog xóa
  const handleDeleteClose = () => {
    setOpenDeleteDialog(false);
    setNewsToDelete(null);
  };

  // Thực hiện xóa
  const handleConfirmDelete = async () => {
    if (!newsToDelete) return;
    await deleteNews(newsToDelete); // Gọi hàm xóa từ NewsContext
    handleDeleteClose();
  };

  return (
    <Box className="home-container">
      <Helmet>
        <title>Trang chủ - Tin tức mới nhất</title>
        <meta
          name="description"
          content="Trang chủ cung cấp những tin tức mới nhất, hấp dẫn nhất từ nhiều lĩnh vực."
        />
        <meta property="og:title" content="Trang chủ - Tin tức mới nhất" />
        <meta
          property="og:description"
          content="Trang chủ cung cấp những tin tức mới nhất, hấp dẫn nhất từ nhiều lĩnh vực."
        />
        <meta
          property="og:image"
          content={
            newsList[0]?.images?.[0] || "https://via.placeholder.com/600"
          }
        />
      </Helmet>

      <Box className="news-list">
        {newsList.map((newsItem, index) => (
          <Box
            key={`${newsItem.id}-${index}`}
            className="news-item"
            sx={{
              padding: "16px",
              boxShadow: "0 2px 6px rgba(0,0,0,0.1)",
              backgroundColor: "#fff",
              position: "relative",
            }}
          >
            {/* CHỈ hiển thị icon Xóa nếu user?.isAdmin === true */}
            {isAdmin && (
              <IconButton
                sx={{
                  position: "absolute",
                  top: "8px",
                  right: "8px",
                  color: "red",
                }}
                onClick={() => handleDeleteOpen(newsItem.id)}
              >
                <DeleteIcon />
              </IconButton>
            )}

            {/* Header: Avatar, tác giả */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: "8px",
              }}
            >
              <Avatar
                src={
                  newsItem.userId?.avatar || "https://via.placeholder.com/150"
                }
                alt={newsItem.author || "Unknown"}
                sx={{ width: 40, height: 40, marginRight: "8px" }}
              />
              <Box>
                <Link
                  to={`/user-wall/${newsItem.authorInfo?.id || "unknown"}`}
                  state={{ userId: newsItem.authorInfo?.id }}
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <Typography sx={{ fontWeight: 600 }}>
                    {newsItem.userId?.name || "Không rõ"}
                  </Typography>
                </Link>
                <Typography sx={{ fontSize: "0.828rem", color: "gray" }}>
                  <span>
                    {newsItem.userId?.personal_title || "Không rõ tác giả"}
                  </span>
                  <span style={{ margin: "0 4px" }}>•</span>
                  <span>{formatDate(newsItem.createdDate)}</span>
                  <span style={{ margin: "0 4px" }}>•</span>
                  <span>{timeAgo(newsItem.createdDate)}</span>
                </Typography>
              </Box>
            </Box>

            {/* Ảnh */}
            <Box sx={{ marginBottom: "8px" }}>
              <img
                src={newsItem.images?.[0] || "https://via.placeholder.com/300"}
                alt={newsItem.title || "News"}
                style={{ width: "100%", borderRadius: "4px" }}
              />
            </Box>

            {/* Tiêu đề + nội dung ngắn */}
            <Box>
              <Link
                to={`/posts/${newsItem.slug}`}
                state={{ id: newsItem.id }}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <Typography
                  sx={{
                    fontWeight: "600",
                    fontSize: "1.125rem",
                    marginBottom: "4px",
                  }}
                >
                  {newsItem.title || "Không có tiêu đề"}
                </Typography>
              </Link>
              <ReactMarkdown children={newsItem.shortIntro || ""} />
            </Box>
          </Box>
        ))}

        {/* Nếu đang loading trang tiếp và vẫn còn tin => spinner */}
        {loading && hasMore && (
          <Box sx={{ textAlign: "center", padding: "16px" }}>
            <CircularProgress />
          </Box>
        )}

        {/* Hết data */}
        {!hasMore && (
          <Box sx={{ textAlign: "center", padding: "8px", color: "gray" }}>
            <Typography variant="body2">Bạn đã xem hết tin hiện có!</Typography>
          </Box>
        )}

        {/* IntersectionObserver bám vào */}
        <div ref={observerRef} style={{ height: 1 }} />
      </Box>

      {/* Dialog Xác nhận Xóa */}
      <Dialog open={openDeleteDialog} onClose={handleDeleteClose}>
        <DialogTitle>Xác Nhận Xóa</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Bạn có chắc chắn muốn xóa bài viết này? Hành động này không thể hoàn
            tác.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteClose} color="primary">
            Hủy
          </Button>
          <Button onClick={handleConfirmDelete} color="error">
            Xóa
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Home;
