import React, { useState, useContext } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import apiClient from "../api/apiClient";
import endpoints from "../api/endpoints";
import { UserContext } from "../context/UserContext";

import "../styles/auth.css";

const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null); // Error state
  const [success, setSuccess] = useState(false); // Success state

  const { setUser } = useContext(UserContext); // Access UserContext
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      const response = await apiClient.post(endpoints.auth.login, {
        email,
        password,
      });

      const { access_token } = response.data;

      // Lưu access token vào localStorage
      localStorage.setItem("access_token", access_token);

      // Kiểm tra xem người dùng có cần thay đổi mật khẩu không
      const userResponse = await apiClient.get(endpoints.users.me, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      });

      const { passwordReset, user } = userResponse.data;

      // Nếu cần thay đổi mật khẩu, điều hướng đến trang thay đổi mật khẩu
      if (passwordReset) {
        navigate("/account/change-password");
        return; // Dừng lại ở đây không tiếp tục thực hiện các bước khác
      }

      // Cập nhật thông tin người dùng trong UserContext
      setUser(user);

      setSuccess(true);

      // Điều hướng và làm mới trạng thái ứng dụng
      setTimeout(() => {
        navigate("/home");
        window.location.reload(); // Làm mới để đảm bảo tất cả trạng thái và dữ liệu đồng bộ
      }, 1000); // Chờ 1 giây để hiển thị thông báo
    } catch (err) {
      setError("Đăng nhập thất bại. Vui lòng kiểm tra lại tài khoản hoặc mật khẩu.");
    }
  };

  return (
    <Box
      sx={{
        maxWidth: "400px",
        margin: "50px auto",
        padding: "20px",
        backgroundColor: "#fff",
        borderRadius: "8px",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        textAlign: "center",
      }}
    >
      <Typography variant="h6" sx={{ marginBottom: "20px" }}>
        Đăng nhập tài khoản
      </Typography>
      <TextField
        fullWidth
        label="Tên tài khoản hoặc Email"
        margin="normal"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <TextField
        fullWidth
        label="Mật khẩu"
        type="password"
        margin="normal"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <Box sx={{ textAlign: "right", marginBottom: "20px" }}>
        <Link to="/reset-password">
          <Typography variant="body2" color="primary">
            Quên mật khẩu?
          </Typography>
        </Link>
      </Box>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        sx={{ marginBottom: "20px" }}
        onClick={handleLogin}
      >
        Đăng nhập
      </Button>
      <Box sx={{ marginTop: "20px" }}>
        <Typography variant="body2">
          Chưa có tài khoản? <Link to="/register">Đăng ký</Link>
        </Typography>
      </Box>

      {/* Snackbar thông báo lỗi */}
      {error && (
        <Snackbar
          open={!!error}
          autoHideDuration={4000}
          onClose={() => setError(null)}
        >
          <Alert severity="error" onClose={() => setError(null)}>
            {error}
          </Alert>
        </Snackbar>
      )}

      {/* Snackbar thông báo thành công */}
      {success && (
        <Snackbar
          open={success}
          autoHideDuration={2000}
          onClose={() => setSuccess(false)}
        >
          <Alert severity="success" onClose={() => setSuccess(false)}>
            Đăng nhập thành công!
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};

export default LoginPage;
