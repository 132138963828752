import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Button,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import apiClient from "../api/apiClient";
import endpoints from "../api/endpoints";
import "../styles/ProductList.css";

const dummySellerData = [
  { id: 1, name: "Seller Product 1", price: "100", image: "https://via.placeholder.com/150" },
  { id: 2, name: "Seller Product 2", price: "200", image: "https://via.placeholder.com/150" },
];

const dummyRenterData = [
  { id: 1, name: "Renter Product 1", price: "300", image: "https://via.placeholder.com/150" },
  { id: 2, name: "Renter Product 2", price: "400", image: "https://via.placeholder.com/150" },
];

const ProductList = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [category, setCategory] = useState("buyer"); // Thêm trạng thái cho danh mục
  const hasFetched = useRef(false); // Sử dụng useRef để lưu trữ cờ

  const fetchProducts = async (page, category) => {
    setIsFetching(true);
    try {
      if (category === "buyer") {
        const response = await apiClient.get(endpoints.products.list(page));
        const newProducts = Array.isArray(response.data.data) ? response.data.data : [];
        setProducts((prevProducts) => [...prevProducts, ...newProducts]);
        setHasMore(newProducts.length > 0);
      } else if (category === "seller") {
        setProducts(dummySellerData);
        setHasMore(false);
      } else if (category === "renter") {
        setProducts(dummyRenterData);
        setHasMore(false);
      }
    } catch (err) {
      setError("Không thể tải danh sách sản phẩm.");
    } finally {
      setLoading(false);
      setIsFetching(false);
    }
  };

  useEffect(() => {
    if (!hasFetched.current) {
      fetchProducts(page, category);
      hasFetched.current = true; // Đặt cờ thành true sau khi gọi API lần đầu tiên
    }
  }, [page, category]);

  const handleLoadMore = () => {
    if (!isFetching) {
      setPage((prevPage) => prevPage + 1);
      hasFetched.current = false; // Đặt cờ thành false để cho phép gọi API lần tiếp theo
    }
  };

  const handleCategoryChange = (event, newCategory) => {
    setCategory(newCategory);
    setProducts([]); // Reset sản phẩm khi thay đổi danh mục
    setPage(1); // Reset trang khi thay đổi danh mục
    hasFetched.current = false; // Đặt cờ thành false để cho phép gọi API lần tiếp theo
  };

  if (loading && page === 1) {
    return (
      <Box sx={{ textAlign: "center", padding: "20px" }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ textAlign: "center", padding: "20px", color: "#ff0000" }}>
        <Typography>{error}</Typography>
      </Box>
    );
  }

  return (
    <Box className="product-list-container">
      <Tabs
        value={category}
        onChange={handleCategoryChange}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        <Tab label="Người bán" value="seller" />
        <Tab label="Người mua" value="buyer" />
        <Tab label="Người cần thuê" value="renter" />
      </Tabs>
      {category === "buyer" && (
        <Grid container spacing={2}>
          {products.map((product) => (
            <Grid item xs={12} sm={6} md={4} key={product._id}>
              <Card className="product-card">
                <CardMedia
                  component="img"
                  height="140"
                  image={product.image || "https://via.placeholder.com/150"}
                  alt={product.name}
                />
                <CardContent>
                  <Typography variant="h6" className="product-name">
                    {product.name}
                  </Typography>
                  <Typography variant="body2" className="product-price">
                    {product.price}
                  </Typography>
                  <Link to={`/products/${product._id}`} className="product-link">
                    <Button variant="contained">Xem chi tiết</Button>
                  </Link>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
      {category === "seller" && (
        <List>
          {products.map((product) => (
            <ListItem key={product.id}>
              <ListItemText primary={product.name} secondary={`Giá: ${product.price}`} />
            </ListItem>
          ))}
        </List>
      )}
      {category === "renter" && (
        <List>
          {products.map((product) => (
            <ListItem key={product.id}>
              <ListItemText primary={product.name} secondary={`Giá: ${product.price}`} />
            </ListItem>
          ))}
        </List>
      )}
      {category === "buyer" && hasMore && (
        <Box sx={{ textAlign: "center", marginTop: "20px" }}>
          <Button
            variant="contained"
            onClick={handleLoadMore}
            disabled={isFetching}
          >
            {isFetching ? "Đang tải..." : "Xem thêm"}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default ProductList;