import React, { useContext, useState, useEffect } from "react";
import {
  Box,
  Typography,
  Avatar,
  Divider,
  Grid,
  Button,
  TextField,
  IconButton,
  Tabs,
  Tab,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { UserContext } from "../context/UserContext";
import apiClient from "../api/apiClient";
import endpoints from "../api/endpoints";
import "../styles/PersonalDetails.css";
import IdentityVerification from "./IdentityVerification";

const PersonalDetails = () => {
  const { user, setUser, loading, error } = useContext(UserContext);
  const [formData, setFormData] = useState({
    name: user?.name || "",
    dob: user?.dob || "",
    personal_title: user?.personal_title || "",
    phone: user?.phone || "",
    avatar: user?.avatar || "",
  });
  const [age, setAge] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [avatarFile, setAvatarFile] = useState(null);
  const [previewAvatar, setPreviewAvatar] = useState(user?.avatar || "");
  const [saving, setSaving] = useState(false);
  const [activeTab, setActiveTab] = useState(0); // Tab state to control tab switching
  const [kycImages, setKycImages] = useState([]); // State for KYC images

  useEffect(() => {
    if (formData.dob) {
      const dob = new Date(formData.dob);
      const diff = new Date().getFullYear() - dob.getFullYear();
      setAge(diff);
    }
  }, [formData.dob]);

  const formatDob = (dob) => {
    const date = new Date(dob);
    const day = ("0" + date.getDate()).slice(-2);
    const month = ("0" + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const handleSave = async () => {
    setSaving(true);
    try {
      let updatedData = { ...formData };

      if (avatarFile) {
        const avatarUrl = await uploadImage(avatarFile);
        updatedData.avatar = avatarUrl;
      }

      const fieldsToUpdate = {};
      Object.keys(updatedData).forEach((key) => {
        if (updatedData[key] !== user[key] && updatedData[key] !== "") {
          fieldsToUpdate[key] = updatedData[key];
        }
      });

      if (Object.keys(fieldsToUpdate).length > 0) {
        const response = await apiClient.put(
          endpoints.users.profile,
          fieldsToUpdate
        );
        setUser(response.data);
        setPreviewAvatar(updatedData.avatar || user.avatar);
      }

      setIsEditing(false);
    } catch (err) {
      console.error("Lỗi khi cập nhật thông tin:", err);
    } finally {
      setSaving(false);
    }
  };

  const handleAvatarChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setAvatarFile(file);
      const objectUrl = URL.createObjectURL(file);
      setPreviewAvatar(objectUrl);
    }
  };

  const uploadImage = async (file) => {
    const formData = new FormData();
    formData.append("image", file);

    try {
      const response = await apiClient.post(
        "https://upload.genyz.vn/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data.public_image;
    } catch (error) {
      console.error("Lỗi tải lên hình ảnh:", error);
      throw new Error("Không thể tải lên hình ảnh.");
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue); // Update the active tab state
  };

  const handleFieldChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  if (loading) {
    return (
      <Box sx={{ textAlign: "center", padding: "20px", color: "#333" }}>
        <Typography>Đang tải...</Typography>
      </Box>
    );
  }

  if (error || !user) {
    return (
      <Box sx={{ textAlign: "center", padding: "20px", color: "#ff0000" }}>
        <Typography>
          {error || "Không thể tải thông tin cá nhân. Vui lòng đăng nhập."}
        </Typography>
      </Box>
    );
  }

  return (
    <Box className="personal-details-container">
      {/* Tabs */}
      <Tabs
        value={activeTab}
        onChange={handleTabChange}
        aria-label="Personal Details Tabs"
        variant="scrollable"
        scrollButtons="auto"
        textColor="inherit"
        sx={{
          "& .MuiTab-root": {
            margin: "0 auto !important",
            color: "#fff",
            fontWeight: "600 !important",
          },
          "& .Mui-selected": {
            borderBottom: "2px solid white !important",
          },
          "& .MuiTabs-flexContainer": {
            padding: "0 20px",
          },
        }}
      >
        <Tab label="Thông Tin" />
        {/* <Tab label="Xác Thực" /> */}
      </Tabs>

      {/* Tab Content */}
      <Box sx={{ padding: "20px" }}>
        {activeTab === 0 && (
          <>
            {/* Personal Info Section */}
            <Box
              sx={{
                textAlign: "center",
                marginBottom: "30px",
                position: "relative",
              }}
            >
              <Avatar
                src={
                  previewAvatar ||
                  user.avatar ||
                  "https://via.placeholder.com/150"
                }
                alt={user.name}
                sx={{
                  width: 120,
                  height: 120,
                  borderRadius: "50%",
                  margin: "10px auto",
                }}
              />
              {isEditing && (
                <IconButton
                  sx={{
                    position: "relative",
                    bottom: "40px",
                    right: "-50px",
                    backgroundColor: "#fff",
                    borderRadius: "50%",
                    padding: "8px",
                  }}
                  onClick={() =>
                    document.getElementById("avatar-upload").click()
                  }
                >
                  <EditIcon />
                </IconButton>
              )}
              <input
                accept="image/*"
                type="file"
                onChange={handleAvatarChange}
                style={{ display: "none" }}
                id="avatar-upload"
              />
              {!isEditing ? (
                <>
                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: "1.3rem",
                      fontWeight: "600",
                    }}
                  >
                    {user.name || "Không rõ"}
                  </Typography>
                  <Typography
                    sx={{ color: "#fff", fontSize: "1rem", fontWeight: "400" }}
                  >
                    {user.email}
                  </Typography>
                </>
              ) : (
                <>
                  <TextField
                    label="Họ & Tên"
                    value={formData.name || ""} // Đảm bảo giá trị mặc định là chuỗi rỗng
                    onChange={(e) => handleFieldChange("name", e.target.value)}
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    InputLabelProps={{
                      style: { color: "#fff" },
                    }}
                    sx={{
                      input: {
                        color: "#fff",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#fff",
                          color: "#fff !important",
                        },
                        "&:hover fieldset": {
                          borderColor: "#3b82f6",
                          color: "#fff !important",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#3b82f6",
                          color: "#fff !important",
                        },
                      },
                    }}
                  />
                  {/* Ẩn trường Email nếu đã xác minh */}
                  {!user?.emailVerified && (
                    <Grid item xs={12} sm={6}>
                      <Typography sx={{ color: "#fff" }}>Email</Typography>
                      {isEditing ? (
                        <TextField
                          label="Email"
                          value={formData.email || user.email}
                          onChange={(e) =>
                            handleFieldChange("email", e.target.value)
                          }
                          fullWidth
                          variant="outlined"
                          margin="dense"
                          InputLabelProps={{
                            style: { color: "#fff" },
                          }}
                          sx={{
                            input: {
                              color: "#fff",
                            },
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                borderColor: "#fff",
                              },
                              "&:hover fieldset": {
                                borderColor: "#3b82f6",
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "#3b82f6",
                              },
                            },
                          }}
                        />
                      ) : (
                        <Typography sx={{ color: "#c3c3c3", fontSize: "14px" }}>
                          {user.email}
                        </Typography>
                      )}
                    </Grid>
                  )}
                </>
              )}
            </Box>

            <Divider sx={{ margin: "30px 0", border: "1px solid gray" }} />

            {/* Contact Info Section */}
            <Box className="personal-section">
              <Typography
                sx={{ color: "#fff", marginBottom: "16px", fontWeight: "600" }}
              >
                LIÊN HỆ
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ color: "#fff" }}>Số điện thoại</Typography>
                  {isEditing && !user?.emailVerified ? (
                    <TextField
                      value={formData.phone || user.phone}
                      onChange={(e) =>
                        handleFieldChange("phone", e.target.value)
                      }
                      fullWidth
                      variant="outlined"
                      margin="dense"
                      InputLabelProps={{
                        style: { color: "#fff" },
                      }}
                      sx={{
                        input: {
                          color: "#fff",
                        },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#fff",
                          },
                          "&:hover fieldset": {
                            borderColor: "#3b82f6",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#3b82f6",
                          },
                        },
                      }}
                    />
                  ) : (
                    <Typography sx={{ color: "#c3c3c3", fontSize: "14px" }}>
                      {user.phone || "Chưa cập nhật"}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Box>
            <Divider sx={{ margin: "20px 0" }} />

            {/* Personal Info Section */}
            <Box className="personal-section">
              <Typography
                sx={{ color: "#fff", marginBottom: "16px", fontWeight: "600" }}
              >
                THÔNG TIN CÁ NHÂN
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ color: "#fff" }}>Danh hiệu</Typography>
                  {isEditing && !user?.emailVerified ? (
                    <TextField
                      value={formData.personal_title || user.personal_title}
                      onChange={(e) =>
                        handleFieldChange("personal_title", e.target.value)
                      }
                      fullWidth
                      variant="outlined"
                      margin="dense"
                      InputLabelProps={{
                        style: { color: "#fff" },
                      }}
                      sx={{
                        input: {
                          color: "#fff",
                        },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#fff",
                          },
                          "&:hover fieldset": {
                            borderColor: "#3b82f6",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#3b82f6",
                          },
                        },
                      }}
                    />
                  ) : (
                    <Typography sx={{ color: "#c3c3c3", fontSize: "14px" }}>
                      {user.personal_title || "Chưa cập nhật"}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ color: "#fff" }}>Ngày sinh</Typography>
                  {isEditing ? (
                    <TextField
                      // label="Ngày sinh"
                      value={formData.dob || user.dob}
                      onChange={(e) => handleFieldChange("dob", e.target.value)}
                      fullWidth
                      variant="outlined"
                      margin="dense"
                      type="date"
                      InputLabelProps={{
                        style: { color: "#fff" },
                      }}
                      sx={{
                        input: {
                          color: "#fff",
                        },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            borderColor: "#fff",
                          },
                          "&:hover fieldset": {
                            borderColor: "#3b82f6",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#3b82f6",
                          },
                        },
                        "& input[type='date']::-webkit-calendar-picker-indicator":
                          {
                            filter: "invert(1)",
                          },
                      }}
                    />
                  ) : (
                    <Typography sx={{ color: "#c3c3c3", fontSize: "14px" }}>
                      {formatDob(user.dob) || "Chưa cập nhật"}
                    </Typography>
                  )}
                  <br />
                  {age && (
                    <Typography sx={{ color: "#c3c3c3" }}>
                      Tuổi: {age}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Box>

            <Divider sx={{ margin: "20px 0" }} />
            <Box sx={{ marginTop: "20px", textAlign: "center" }}>
              {!isEditing && (
                <Button
                  variant="outlined"
                  sx={{
                    marginTop: "10px",
                    marginLeft: "10px",
                    borderRadius: "20px",
                    borderColor: "#fff",
                    color: "#fff",
                    "&:hover": {
                      borderColor: "#3b82f6",
                      color: "#3b82f6",
                    },
                  }}
                  onClick={() => setIsEditing(true)}
                >
                  Chỉnh sửa
                </Button>
              )}

              {isEditing && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "10px",
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      marginTop: "10px",
                      borderRadius: "20px",
                      backgroundColor: "#3b82f6",
                      "&:hover": { backgroundColor: "#2563eb" },
                    }}
                    onClick={handleSave}
                    disabled={saving}
                  >
                    {saving ? "Đang lưu..." : "Lưu thay đổi"}
                  </Button>

                  <Button
                    variant="outlined"
                    sx={{
                      marginTop: "10px",
                      borderRadius: "20px",
                      borderColor: "#3b82f6",
                      color: "#3b82f6",
                      "&:hover": {
                        borderColor: "#2563eb",
                        color: "#2563eb",
                      },
                    }}
                    onClick={() => setIsEditing(false)}
                  >
                    Hủy
                  </Button>
                </Box>
              )}
            </Box>
          </>
        )}

        {activeTab === 1 && (
          <IdentityVerification
            kycImages={kycImages}
            setKycImages={setKycImages}
          />
        )}
      </Box>
    </Box>
  );
};

export default PersonalDetails;
