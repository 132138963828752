import React from "react";
import { Link, useParams } from "react-router-dom";
import categoriesData from "../data/categories.json";
import "../styles/Breadcrumb.css";

const Breadcrumb = ({ subName }) => {
  const { categoryId, threadId } = useParams();
  const category = categoriesData.categories.find(
    (cat) => cat.id === parseInt(categoryId)
  );

  return (
    <div className="breadcrumb">
      <Link to="/categories" className="breadcrumb-link">Chuyên mục</Link>
      {categoryId && (
        <>
          <span className="breadcrumb-separator"> &gt; </span>
          <Link to={`/categories/${categoryId}`} className="breadcrumb-link">
            {category?.name || subName}
          </Link>
        </>
      )}
      {threadId && (
        <>
          <span className="breadcrumb-separator"> &gt; </span>
          <span className="breadcrumb-thread">Bài viết #{threadId}</span>
        </>
      )}
    </div>
  );
};

export default Breadcrumb;
