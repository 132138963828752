import React from "react";
import BottomNavigationBar from "./components/BottomNavigationBar";
import DesktopMenu from "./components/DesktopMenu";
import Footer from "./components/Footer";
import { Box, useMediaQuery } from "@mui/material";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import Home from "./pages/Home";
import ShortNews from "./pages/News";
import ShortNewsDetail from "./pages/ShortNewsDetail";

import Market from "./pages/Market";
import Profile from "./pages/Profile";
import DetailsNews from "./pages/DetailsNews";
// import Entertainment from "./pages/Entertainment";

import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";

import SendVerificationEmail from "./pages/SendVerificationEmail";
import VerifyEmail from "./pages/VerifyEmail";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import ChangePasswordPage from "./pages/ChangePasswordPage";

// import Categories from "./pages/Categories";

import CategoriesPage from "./pages/CategoriesPage";
import ThreadsPage from "./pages/ThreadsPage";
import ThreadDetailsPage from "./pages/ThreadDetailsPage";

import NoiQuy from "./pages/NoiQuy";
import ServiceAgreement from "./pages/ServiceAgreement";

import TipsAndTricks from "./pages/TipsAndTricks";
import TipDetails from "./pages/TipDetails";

import ProductList from "./components/ProductList"; // Import ProductList
import ProductDetails from "./components/ProductDetails"; // Import ProductDetails

import UserWall from "./components/UserWall";
import PersonalDetails from "./components/PersonalDetails";

const App = () => {
  const isMobile = useMediaQuery("(max-width:768px)");
  const menuHeight = 0;
  const marginBottom = 100;

  return (
    <Box>
      <Router>
        <Box>
          {!isMobile && <DesktopMenu />}
          <Box
            sx={{
              flexGrow: 1,
              overflowY: "auto",
              paddingTop: !isMobile ? "5px" : `${menuHeight}px`,
              marginBottom: `${marginBottom}px`,
            }}
          >
            <Routes>
              <Route path="/" element={<Navigate to="/home" />} />
              <Route path="/home" element={<Home />} />
              <Route path="/posts/:slug" element={<DetailsNews />} />
              <Route path="/news" element={<ShortNews />} />
              <Route path="/news/:slug" element={<ShortNewsDetail />} />
              <Route path="/news/:id" element={<DetailsNews />} />
              <Route path="/market" element={<Market />} />
              {/* <Route path="/entertainment" element={<Entertainment />} /> */}
              <Route path="/profile" element={<Profile />} />
              <Route
                path="/send-verification-email"
                element={<SendVerificationEmail />}
              />
              <Route path="/verify-email" element={<VerifyEmail />} />
              <Route path="/reset-password" element={<ResetPasswordPage />} />
              <Route
                path="/account/change-password"
                element={<ChangePasswordPage />}
              />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/register" element={<RegisterPage />} />
              <Route path="/categories" element={<CategoriesPage />} />
              <Route path="/categories/:categoryId" element={<ThreadsPage />} />
              <Route
                path="/categories/:categoryId/threads/:threadId"
                element={<ThreadDetailsPage />}
              />
              <Route path="/tips-and-tricks" element={<TipsAndTricks />} />
              <Route path="/tips-and-tricks/:tipId" element={<TipDetails />} />
              <Route path="/products" element={<ProductList />} />{" "}
              {/* Thêm route cho ProductList */}
              <Route path="/products/:id" element={<ProductDetails />} />{" "}
              {/* Thêm route cho ProductDetails */}
              <Route path="/user-wall/:userId" element={<UserWall />} />
              <Route
                path="/account/personal-details"
                element={<PersonalDetails />}
              />
              <Route path="/noi-quy-chung" element={<NoiQuy />} />
              <Route
                path="/thoa-thuan-cung-cap-su-dung-dich-vu"
                element={<ServiceAgreement />}
              />
            </Routes>
          </Box>
          {isMobile && <BottomNavigationBar />}
          {!isMobile && <Footer />}
        </Box>
      </Router>
    </Box>
  );
};

export default App;
