import React, { useContext, useState } from "react";
import {
  Box,
  Typography,
  Avatar,
  Button,
  Tabs,
  Tab,
  Divider,
  CircularProgress,
  useMediaQuery,
  useTheme
} from "@mui/material";
import {
  // Edit,
  // CheckCircle,
  Cancel,
  // ReportProblem,
  Email,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import "../styles/Profile.css";

const Profile = () => {
  const { user, loading, setUser } = useContext(UserContext);
  const [activeTab, setActiveTab] = useState(0);
  const navigate = useNavigate();

  // Use MUI's useMediaQuery to detect if the screen size is mobile
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  // const renderKycStatus = (status) => {
  //   switch (status) {
  //     case "verified":
  //       return (
  //         <Box className="kyc-status verified">
  //           <CheckCircle className="kyc-icon" />
  //           <Typography variant="body2" className="kyc-text">
  //             Tài khoản đã xác minh
  //           </Typography>
  //         </Box>
  //       );
  //     case "pending":
  //       return (
  //         <Box className="kyc-status pending">
  //           <ReportProblem className="kyc-icon" />
  //           <Typography variant="body2" className="kyc-text">
  //             Đang chờ xác minh
  //           </Typography>
  //         </Box>
  //       );
  //     default:
  //       return (
  //         <Box className="kyc-status none">
  //           <Cancel className="kyc-icon" />
  //           <Typography variant="body2" className="kyc-text">
  //             Tài khoản chưa xác minh
  //           </Typography>
  //         </Box>
  //       );
  //   }
  // };

  const renderEmailVerificationStatus = (emailVerified) => {
    if (!emailVerified) {
      return (
        <Box className="kyc-status none">
          <Cancel className="kyc-icon" />
          <Typography variant="body2" className="kyc-text">
            Bạn cần xác minh email
          </Typography>
        </Box>
      );
    }
  };

  if (loading) {
    return (
      <Box
        className="profile-page"
        sx={{ textAlign: "center", marginTop: "50px" }}
      >
        <CircularProgress color="primary" />
      </Box>
    );
  }

  if (!user) {
    return (
      <Box
        className="profile-page"
        sx={{ textAlign: "center", marginTop: "50px" }}
      >
        <Typography sx={{ marginBottom: "50px" }}>Không thể tải thông tin tài khoản. Vui lòng đăng nhập.</Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/login")}
        >
          Đăng nhập
        </Button>
      </Box>
    );
  }

  return (
    <Box className="profile-page">
      {/* Header */}
      <Box className="profile-header">
        <Avatar
          className="profile-avatar"
          src={user?.avatar || "https://via.placeholder.com/150"}
          alt="Avatar"
        />
        <Typography variant="h5" className="profile-name">
          {user?.name || "Không rõ"}
        </Typography>
        <Divider />
        {/* KYC Status */}
        {/* {renderKycStatus(user?.kycStatus || "none")} */}
        <Divider />
        {/* Email Verification Status */}
        {renderEmailVerificationStatus(user?.emailVerified)}

        <Typography className="profile-info">
          Cấp thành viên: <strong>{user?.personal_title || "Không có"}</strong>
        </Typography>
        {/* <Typography className="profile-info">
          Bài đã đăng: {user?.posts_count || 0} | Lượt like:{" "}
          {user?.likes_count || 0} | Lượt theo dõi: {user?.followers_count || 0}
        </Typography> */}
        
        {/* {user?.emailVerified && (
          <Button
            variant="contained"
            className="new-post-btn"
            startIcon={<Edit />}
          >
            Đăng Bài Viết Ngay
          </Button>
        )} */}
        
        {!user?.emailVerified && (
          <Button
            variant="outlined"
            startIcon={<Email />}
            className="new-post-btn"
            onClick={() => navigate("/send-verification-email")}
          >
            Xác minh tài khoản ngay
          </Button>
        )}

        {/* Mobile Button for Personal Details */}
        {isMobile && (
          <>
            <Divider className="profile-divider" />
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
                width: "100%"
              }}
            >
              <Button
                variant="outlined"
                onClick={() => navigate("/account/personal-details")}
                className="personal-details-btn"
              >
                Thông tin cá nhân
              </Button>
            </Box>
            <Button
              variant="outlined"
              className="logout-btn"
              onClick={() => {
                localStorage.removeItem("access_token");
                setUser(null); // Xóa trạng thái user trong context
                navigate("/home");
              }}
            >
              Đăng xuất
            </Button>
          </>
        )}

      </Box>

      <Divider className="profile-divider" />

      {/* Tabs */}
      <Box className="tabs-wrapper">
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="inherit"
          variant="scrollable"
          scrollButtons="auto"
          className="profile-tabs"
        >
          <Tab label="Tường nhà" />
          <Tab label="Nhiệm vụ của bạn" />
          <Tab label="Phân tích" />
        </Tabs>
      </Box>

      <Divider className="profile-divider" />

      {/* Tab Content */}
      <Box className="tab-content">
        {activeTab === 0 && (
          <Box className="tab-panel">
            <Typography variant="h6">Tường nhà</Typography>
            <Typography>
              Hiển thị các bài viết, hoạt động trên tường nhà của bạn.
            </Typography>
          </Box>
        )}
        {activeTab === 1 && (
          <Box className="tab-panel">
            <Typography variant="h6">Nhiệm vụ của bạn</Typography>
            <Typography>
              Hiển thị các nhiệm vụ và phần thưởng bạn đã hoàn thành.
            </Typography>
          </Box>
        )}
        {activeTab === 2 && (
          <Box className="tab-panel">
            <Typography variant="h6">Phân tích</Typography>
            <Typography>
              Hiển thị thống kê và phân tích hoạt động của bạn.
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Profile;
