const endpoints = {
  auth: {
    login: "/auth/login",
    logout: "/auth/logout",
  },
  users: {
    register: "/users/register",
    getAll: "/users",
    getById: (id: string) => `/users/${id}`,
    updateRoles: (id: string) => `/users/${id}/roles`,
    profile: "/users/me",
    updateProfile: "/users/me",
    sendVerificationEmail: "/users/send-verification-email",
    verifyEmail: "/users/verify-email",
    getByEmail: (email: string) => `/users/${email}`,
    resetPassword: "/users/reset-password",
    changePassword: "/users/change-password",
    me: "/users/me",
  },
  roles: {
    create: "/roles",
    getAll: "/roles",
    getByName: (name: string) => `/roles/${name}`,
    updateByName: (name: string) => `/roles/${name}`,
    deleteByName: (name: string) => `/roles/${name}`,
  },
  email: {
    send: "/email/send",
  },
  categories: {
    list: "/categories",
    details: (id: string) => `/categories/${id}`,
    create: "/categories",
    update: (id: string) => `/categories/${id}`,
    delete: (id: string) => `/categories/${id}`,
  },
  news: {
    list: "/news",
    details: (id: string) => `/news/${id}`,
    detailsBySlug: (slug: string) => `/news/slug/${slug}`,
    create: "/news",
    update: (id: string) => `/news/${id}`,
    delete: (id: string) => `/news/${id}`,
    updateStatus: (id: string) => `/news/${id}/status`,
  },
  activities: {
    list: "/activities",
    details: (id: string) => `/activities/${id}`,
    count: "/activities/count",
    create: "/activities",
    update: (id: string) => `/activities/${id}`,
    delete: (id: string) => `/activities/${id}`,
  },
  shortNews: {
    list: (page: number = 1, limit: number = 10) =>
      `/short-news?page=${page}&limit=${limit}`,
    details: (id: string) => `/short-news/${id}`,
    detailsBySlug: (slug: string) => `/short-news/slug/${slug}`,
    create: "/short-news",
    update: (id: string) => `/short-news/${id}`,
    delete: (id: string) => `/short-news/${id}`,
    updateStatus: (id: string) => `/short-news/${id}/status`,
  },
  notifications: {
    create: "/notifications",
    list: "/notifications",
    markAsRead: (id: string) => `/notifications/${id}/read`,
    unreadCount: "/notifications/unread-count",
  },
  tipsAndTricksEndpoints: {
    create: "/tips-and-tricks",
    list: "/tips-and-tricks",
    details: (id: string) => `/tips-and-tricks/${id}`,
    update: (id: string) => `/tips-and-tricks/${id}`,
    delete: (id: string) => `/tips-and-tricks/${id}`,
    Status: (id: string) => `/tips-and-tricks/${id}/status`,
  },
  discussionCategory: {
    list: "/discussion-category/topics",
    topicId: (id: string) => `/discussion-category/topic/${id}`,
    topicIdThread: (id: string, page: number = 1, limit: number = 10) => `/discussion-category/topic/${id}/threads?page=${page}&limit=${limit}`,
    threadDetails: (id: string) => `/discussion-category/thread/${id}`,
    threadComment: (id: string) => `/discussion-category/thread/${id}/comments`,
    createThread: "/discussion-category/thread", // Endpoint để tạo thread mới
    createComment: "/discussion-category/comment", // Endpoint để tạo bình luận mới
    deleteThread: (id: string) => `/discussion-category/thread/${id}`,
    hideComment: (id: string) => `/discussion-category/comment/hidden/${id}`,
  },

  productCategories: {
    create: "/product-categories",
    list: "/product-categories",
    details: (id: string) => `/product-categories/${id}`,
    update: (id: string) => `/product-categories/${id}`,
    delete: (id: string) => `/product-categories/${id}`,
  },
  products: {
    create: "/products",
    list: (page: number = 1, limit: number = 10) =>
      `/products?page=${page}&limit=${limit}`,
    details: (id: string) => `/products/${id}`,
    update: (id: string) => `/products/${id}`,
    delete: (id: string) => `/products/${id}`,
    findByCategory: (
      categoryId: string,
      page: number = 1,
      limit: number = 10
    ) => `/products/category/${categoryId}?page=${page}&limit=${limit}`,
  },
};

export default endpoints;
