import React, { useEffect, useState, useRef } from "react";
import { Helmet } from "react-helmet-async"; // Import Helmet
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Box, Typography, Button } from "@mui/material";
import ReactMarkdown from "react-markdown";
import apiClient from "../api/apiClient";
import endpoints from "../api/endpoints";
import { formatDate, timeAgo } from "../utils/dateUtils";

import "../styles/ShortNewsDetail.css";

const ShortNewsDetail = () => {
  const { slug } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = location.state || {};
  const [newsDetail, setNewsDetail] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const isFetched = useRef(false);

  useEffect(() => {
    const fetchNewsDetail = async () => {
      let fetchUrl = "";

      if (id) {
        fetchUrl = endpoints.shortNews.details(id);
      } else if (slug) {
        fetchUrl = endpoints.shortNews.detailsBySlug(slug);
      } else {
        navigate("/news");
        return;
      }

      if (isFetched.current) return;

      try {
        isFetched.current = true;
        const response = await apiClient.get(fetchUrl);
        setNewsDetail(response.data);
      } catch (err) {
        console.error("Lỗi khi tải chi tiết bài viết:", err);
        setError("Không thể tải nội dung bài viết. Vui lòng thử lại sau!");
      } finally {
        setLoading(false);
      }
    };

    fetchNewsDetail();
  }, [id, slug, navigate]);

  const isMarkdown = (text) => {
    return /[#*_`]/.test(text);
  };

  if (loading) {
    return <Box sx={{ textAlign: "center", color: "#fff" }}>Đang tải...</Box>;
  }

  if (error) {
    return (
      <Box sx={{ textAlign: "center", padding: "20px" }}>
        <Typography color="error">{error}</Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/news")}
        >
          Quay lại
        </Button>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        padding: "20px",
        maxWidth: "800px",
        margin: "0 auto",
        color: "#fff",
      }}
      className="short-news-container"
    >
      {/* SEO Metadata */}
      <Helmet>
        {/* SEO Metadata */}
        <title>{newsDetail.title || "Chi tiết bài viết"}</title>
        <meta
          name="description"
          content={
            newsDetail.description ||
            "Xem chi tiết bài viết và cập nhật thông tin mới nhất."
          }
        />

        {/* Open Graph Metadata */}
        <meta
          property="og:title"
          content={newsDetail.title || "Chi tiết bài viết"}
        />
        <meta
          property="og:description"
          content={
            newsDetail.description ||
            "Xem chi tiết bài viết và cập nhật thông tin mới nhất."
          }
        />
        <meta
          property="og:image"
          content={newsDetail.image || "https://via.placeholder.com/600"}
        />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="article" />
        <meta property="og:locale" content="vi_VN" />

        {/* Twitter Card Metadata */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content={newsDetail.title || "Chi tiết bài viết"}
        />
        <meta
          name="twitter:description"
          content={
            newsDetail.description ||
            "Xem chi tiết bài viết và cập nhật thông tin mới nhất."
          }
        />
        <meta
          name="twitter:image"
          content={newsDetail.image || "https://via.placeholder.com/600"}
        />
        <meta name="twitter:url" content={window.location.href} />
      </Helmet>

      {/* Tiêu đề bài viết */}
      <Typography variant="h4" gutterBottom sx={{ color: "#fff" }}>
        {newsDetail.title}
      </Typography>

      {/* Thông tin meta */}
      <Typography
        variant="body2"
        color="textSecondary"
        gutterBottom
        sx={{
          fontSize: "12px",
          color: "gray",
          textTransform: "uppercase",
          marginBottom: "30px",
        }}
      >
        {newsDetail.userId.name || "Không rõ tác giả"} -{" "}
        {formatDate(newsDetail.createdDate)} • {timeAgo(newsDetail.createdDate)}
      </Typography>

      {/* Hình ảnh bài viết */}
      <img
        src={newsDetail.image || "https://via.placeholder.com/600"}
        alt={newsDetail.title}
        style={{
          width: "100%",
          borderRadius: "8px",
        }}
      />

      {/* Mô tả */}
      {newsDetail.description && (
        <Box
          sx={{
            marginBottom: "20px",
            color: "#e0e0e0",
            fontSize: "1rem !important",
          }}
          className="content-markdown"
        >
          {isMarkdown(newsDetail.description) ? (
            <ReactMarkdown>{newsDetail.description}</ReactMarkdown>
          ) : (
            <Typography>{newsDetail.description}</Typography>
          )}
        </Box>
      )}

      {/* Nội dung bài viết */}
      <Box
        sx={{
          lineHeight: "1.6",
          fontSize: "1.1rem",
        }}
        className="markdown"
      >
        {isMarkdown(newsDetail.content) ? (
          <ReactMarkdown>{newsDetail.content}</ReactMarkdown>
        ) : (
          <Typography>{newsDetail.content}</Typography>
        )}
      </Box>
    </Box>
  );
};

export default ShortNewsDetail;
