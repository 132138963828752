import React, { createContext, useContext, useState, useEffect, useRef } from "react";
import apiClient from "../api/apiClient";
import endpoints from "../api/endpoints";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const hasFetched = useRef(false);

  useEffect(() => {
    const fetchUserProfile = async () => {
      // Nếu đã fetch một lần => bỏ qua
      if (hasFetched.current) return;
      hasFetched.current = true;

      const token = localStorage.getItem("access_token");

      if (!token) {
        setLoading(false);
        return;
      }

      try {
        const response = await apiClient.get(endpoints.users.profile, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Bổ sung check isAdmin dựa trên mảng roles
        let userData = response.data;
        if (userData && Array.isArray(userData.roles)) {
          userData.isAdmin = userData.roles.includes("admin");
        }

        setUser(userData); // Lưu thông tin user (có isAdmin nếu roles tồn tại)
      } catch (err) {
        console.error("Lỗi khi gọi API profile:", err);
        setError("Không thể tải thông tin người dùng. Vui lòng đăng nhập lại.");
        localStorage.removeItem("access_token");
      } finally {
        setLoading(false);
      }
    };

    fetchUserProfile();
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser, loading, error }}>
      {children}
    </UserContext.Provider>
  );
};

// Hook để sử dụng UserContext
export const useUserContext = () => useContext(UserContext);
