import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import apiClient from "../api/apiClient";
import endpoints from "../api/endpoints";
import ReactMarkdown from "react-markdown";
import { Link } from "react-router-dom";
import "../styles/TipDetails.css";
import { formatDate, timeAgo } from "../utils/dateUtils";

const TipDetails = () => {
  const { tipId } = useParams(); // Lấy tipId từ URL
  const [tip, setTip] = useState(null); // State cho chi tiết tip
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const hasFetched = useRef(false); // Ref để kiểm tra fetch đã được thực hiện chưa

  useEffect(() => {
    if (hasFetched.current) return; // Nếu đã fetch rồi, không làm gì
    hasFetched.current = true; // Đánh dấu đã fetch

    const fetchTipDetails = async () => {
      try {
        const response = await apiClient.get(
          endpoints.tipsAndTricksEndpoints.details(tipId)
        );
        // console.log("API Response for Tip Details:", response.data); // Kiểm tra dữ liệu trả về

        const tipData = response.data;

        setTip(tipData);
      } catch (err) {
        console.error("Lỗi khi lấy dữ liệu chi tiết Tip:", err);
        setError("Không thể tải dữ liệu chi tiết. Vui lòng thử lại sau!");
      } finally {
        setLoading(false);
      }
    };

    fetchTipDetails();
  }, [tipId]);

  const isMarkdown = (text) => {
    return /[#*_`]/.test(text); // Phát hiện Markdown đơn giản
  };

  if (loading) {
    return <div className="tip-details-loading">Đang tải...</div>;
  }

  if (error) {
    return <div className="tip-details-error">{error}</div>;
  }

  if (!tip) {
    return (
      <div className="tip-details-no-data">
        Không tìm thấy Tips & Tricks này.
      </div>
    );
  }

  return (
    <div className="tip-details-container">
      <h1 className="tip-details-title">{tip.title || "No Title"}</h1>
      <Link
        to={`/user-wall/${tip.authorInfo?.userId || "unknown"}`}
        state={{ userId: tip.authorInfo?.userId }}
        style={{ textDecoration: "none", color: "inherit" }}
      >
        <p className="tip-details-meta">
          {tip.userId?.name || "Unknown"} • {formatDate(tip.createdDate)} •{" "}
          {timeAgo(tip.createdDate)}
        </p>
      </Link>
      {tip.image && (
        <img
          src={tip.image}
          alt={tip.title || "No Title"}
          className="tip-details-image"
        />
      )}
      <div className="tip-details-content">
        {isMarkdown(tip.summary) ? (
          <ReactMarkdown>{tip.summary}</ReactMarkdown>
        ) : (
          <p>{tip.summary || "No Content Available."}</p>
        )}
      </div>
    </div>
  );
};

export default TipDetails;
