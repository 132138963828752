import React from "react";
import logo from "../assets/logo-9bricks.png";

import "../styles/HeaderLogo.css";

const HeaderLogo = () => {
    return (
      <div className="header-logo">
        <img src={logo} alt="Logo" className="header-logo-image" />
        <span>Genyz</span>
      </div>
    );
  };
  

export default HeaderLogo;
