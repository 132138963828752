import React, { useState, useEffect, useContext } from "react";
import { useParams, useLocation } from "react-router-dom";
import Breadcrumb from "../components/Breadcrumb";
import apiClient from "../api/apiClient";
import endpoints from "../api/endpoints";
import "../styles/ThreadDetails.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";
import { UserContext } from "../context/UserContext";
import CommentItem from "./CommentItem"; 
import { formatDate } from "../utils/dateUtils";

const ThreadDetailsPage = () => {
  const location = useLocation();
  const { data } = location.state || {};
  const { categoryId, threadId } = useParams();
  const [thread, setThread] = useState(null);
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [commentContent, setCommentContent] = useState("");

  const { user } = useContext(UserContext);

  useEffect(() => {
    const fetchThreadDetails = async () => {
      try {
        const threadResponse = await apiClient.get(
          endpoints.discussionCategory.threadDetails(threadId)
        );
        setThread(threadResponse.data);

        const commentsResponse = await apiClient.get(
          endpoints.discussionCategory.threadComment(threadId)
        );
        setComments(commentsResponse.data);
        setLoading(false);
      } catch (error) {
        setError("Không thể tải dữ liệu. Vui lòng thử lại!");
        setLoading(false);
      }
    };

    fetchThreadDetails();
  }, [threadId]);

  const handleCommentSubmit = async () => {
    if (!commentContent) return;

    try {
      const token = localStorage.getItem("access_token");
      if (!token) {
        alert("Bạn cần đăng nhập để bình luận.");
        return;
      }

      await apiClient.post(
        endpoints.discussionCategory.createComment,
        {
          content: commentContent,
          threadId: threadId,
          createdBy: user.email,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Tải lại danh sách bình luận sau khi gửi bình luận
      const commentsResponse = await apiClient.get(
        endpoints.discussionCategory.threadComment(threadId)
      );
      setComments(commentsResponse.data);
      setCommentContent("");
    } catch (error) {
      console.error("Lỗi khi gửi bình luận:", error);
    }
  };

  const handleHideComment = async (commentId) => {
    try {
      const token = localStorage.getItem("access_token");
      if (!token) {
        alert("Bạn cần đăng nhập để ẩn bình luận.");
        return;
      }

      await apiClient.post(
        endpoints.discussionCategory.hideComment(commentId), // Gọi API để ẩn bình luận
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Cập nhật lại danh sách bình luận sau khi ẩn
      const updatedComments = comments.filter(comment => comment._id !== commentId);
      setComments(updatedComments);
    } catch (error) {
      console.error("Lỗi khi ẩn bình luận:", error);
    }
  };

  if (loading) {
    return <div className="thread-details-loading">Đang tải...</div>;
  }

  if (error) {
    return <div className="thread-details-error">{error}</div>;
  }

  return (
    <div className="thread-details-container">
      <Breadcrumb categoryId={categoryId} threadId={threadId} subName={data} />

      <div className="thread-section">
        <h1 className="thread-title">{thread.title}</h1>
        <p className="thread-meta">
          <span className="author">
            Bởi: {thread.createdBy.name} • {formatDate(thread.createdAt)}
          </span>
        </p>
        <div className="thread-content">
          <div dangerouslySetInnerHTML={{ __html: thread.content }} />
        </div>
      </div>
      <h2 className="comments-title">Bình luận: </h2>
      <div className="new-comment-section">
        {localStorage.getItem("access_token") ? (
          <>
            <p>
              Bình luận với tư cách:{" "}
              <strong
                style={{
                  background: `linear-gradient(to right,rgb(41, 255, 169),rgb(7, 147, 255))`,
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                {user.name}
              </strong>
            </p>
            <ReactQuill
              value={commentContent}
              onChange={setCommentContent}
              placeholder="Bạn có thể để lại bình luận tại đây..."
            />
            <br />
            <Button
              variant="contained"
              color="primary"
              onClick={handleCommentSubmit}
              startIcon={<EditIcon />}
            >
              Gửi bình luận
            </Button>
          </>
        ) : (
          <p>Bạn cần đăng nhập để bình luận.</p>
        )}
      </div>

      <div className="comments-section">
        {comments.length > 0 ? (
          comments.map((comment) => (
            <CommentItem
              key={comment._id}
              comment={comment}
              onHide={handleHideComment} // Truyền hàm onHide vào comment item
              isAdmin={user && user.roles.includes("admin")} // Kiểm tra quyền admin
            />
          ))
        ) : (
          <p>Chưa có bình luận nào.</p>
        )}
      </div>
    </div>
  );
};

export default ThreadDetailsPage;