import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper/modules";
import FeatureCard from "../components/FeatureCard";
import featureData from "../data/featureCardsMock.json";

const FeatureSlider = () => {
  return (
    <Swiper
      modules={[Pagination, Navigation]}
      pagination={{ clickable: true }}
      navigation
      spaceBetween={20}
      slidesPerView={1} // Hiển thị 1.5 card một lúc
      breakpoints={{
        768: {
          slidesPerView: 1.5, // Hiển thị 2.5 card trên màn hình rộng
        },
        1024: {
          slidesPerView: 1, // Hiển thị 3.5 card trên màn hình rất rộng
        },
      }}
      style={{ padding: "16px 20px" }}
    >
      {featureData.features.map((feature) => (
        <SwiperSlide key={feature.id}>
          <FeatureCard feature={feature} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default FeatureSlider;
