// src/components/SendVerificationEmail.js

import React, { useEffect, useState } from "react";
import { Typography, Button, CircularProgress } from "@mui/material";
import apiClient from "../api/apiClient";
import { useNavigate } from "react-router-dom";
import "../styles/SendVerificationEmail.css"; // Import file CSS

const SendVerificationEmail = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(true);
  const [sending, setSending] = useState(false);
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserEmail = async () => {
      try {
        const response = await apiClient.get("/users/me");
        setEmail(response.data.email);
      } catch (error) {
        console.error("Lỗi khi lấy email:", error);
        setError("Không thể lấy thông tin email.");
      } finally {
        setLoading(false);
      }
    };
    fetchUserEmail();
  }, []);

  const handleSendEmail = async () => {
    if (!email) {
      setError("Không có email để gửi xác nhận.");
      return;
    }
    setSending(true);
    setError("");
    setSuccessMessage("");

    try {
      const response = await apiClient.post("/users/send-verification-email", {
        email,
      });

      if (response.status === 200) {
        setSuccessMessage(response.data.message || "Mã xác nhận đã được gửi.");
        setTimeout(() => {
          navigate("/verify-email", { state: { email } });
        }, 2000); // Chờ 2 giây trước khi chuyển trang
      }
    } catch (error) {
      if (
        error.response?.status === 500 ||
        error.response?.message === "Internal server error"
      ) {
        setError("Có lỗi xảy ra trên máy chủ. Vui lòng thử lại sau.");
      } else {
        setError(
          error.response?.data?.message || "Không thể gửi email xác thực."
        );
      }
    } finally {
      setSending(false);
    }
  };

  if (loading) {
    return (
      <div className="send-verification-container">
        <CircularProgress color="primary" />
      </div>
    );
  }

  return (
    <div className="send-verification-container">
      <Typography variant="h5" className="send-verification-title">
        XÁC THỰC TÀI KHOẢN
      </Typography>
      {email ? (
        <Typography className="send-verification-info">
          Chúng tôi sẽ gửi mã xác nhận đến địa chỉ email của bạn:
          <br />
          <br />
          <Typography className="send-verification-email">
            {email}
          </Typography>
        </Typography>
      ) : (
        <Typography className="send-verification-error">
          {error || "Không tìm thấy email."}
        </Typography>
      )}
      {successMessage && (
        <Typography className="send-verification-success">
          {successMessage}
        </Typography>
      )}
      {error && !email && (
        <Typography className="send-verification-error">
          {error}
        </Typography>
      )}
      <Button
        variant="contained"
        color="primary"
        onClick={handleSendEmail}
        disabled={sending || !email}
        className="send-verification-button"
      >
        {sending ? <CircularProgress size={24} color="inherit" /> : "Gửi mã xác nhận"}
      </Button>
    </div>
  );
};

export default SendVerificationEmail;
